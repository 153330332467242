import React, { Component, useState, useEffect } from "react";
import { ToastMessage, Toast, toastMsgConfig } from "../../components/toast";
import HELPERS from "../../utils/helper";
import apis from "../../config/apis";
import { connect } from "react-redux";
import { ErrorMessageHandling } from "../../components/error";

const ChangePassword = ({
  handleClose,
  show,
  access_token,
  user_id,
  student_auth,
  user_info,
  ...props
}) => {
  const showHideClassName = show ? "alert display-block" : "alert display-none";
  const [old_pass, setOldPass] = useState("");
  const [new_pass, setNewPass] = useState("");
  const [confirm_pass, setConfirmPass] = useState("");
  const [show_old_pass, showOldPass] = useState(false);
  const [show_new_pass, showNewPass] = useState(false);
  const [show_confirm_pass, showConfirmPass] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [loader, setLoader] = useState(false);

  function clearState() {
    handleClose();
    setOldPass("");
    setNewPass("");
    setConfirmPass("");
    showOldPass(false);
    showNewPass(false);
    showConfirmPass(false);
    setDisabled(false);
  }

  async function message(type, message) {
    Toast(message, toastMsgConfig(type));
  }

  async function changePass() {
    setLoader(true);
    let headers = { headers: { Authorization: student_auth } };
    let payload = {
      institute_id: access_token.institute_id,
      oldPassword: old_pass,
      newPassword: new_pass,
      userid: user_id,
    };
    try {
      let res = await HELPERS.ApiCall(
        apis.CHANGE_PASSWORD,
        payload,
        "POST",
        "proctur",
        headers
      );
      if (res.status == 201 && res.data.statusCode == 200) {
        message("s", res.data.message);
        setTimeout(() => {
          clearState();
          props.dispatch({ type: "LOGOUT" });
        }, 3000);
      } else {
        message("e", res.data.message);
      }
      setLoader(false);
    } catch (error) {
      if (error.response && error.response.status == 403) {
        props.dispatch({
          type: "LOGOUT",
          message:
            error.response.data && error.response.data.message
              ? error.response.data.message
              : "",
        });
      } else {
        message("e", ErrorMessageHandling(error));
      }
      setLoader(false);
    }
  }

  function validate() {
    setDisabled(true);
    setTimeout(() => {
      setDisabled(false);
    }, 3000);
    if (old_pass !== user_info.password) {
      message("e", "Please enter valid current password!");
    } else if (new_pass.length < 4) {
      message("e", "Password length should be atleast 4 digits long");
    } else if (new_pass !== confirm_pass) {
      message("e", "New password and confirm password does not match");
    } else {
      changePass();
    }
  }

  return (
    <>
      {loader && <div className="dashboardLoader"></div>}
      <ToastMessage />
      <div className={showHideClassName}>
        <section className="alert-main changePasswordBlock ">
          <h4>Change Password</h4>
          <div className="changePass">
            <label className="password">
              <input
                className="loginInput"
                onChange={(e) => {
                  setOldPass(e.target.value.trim());
                }}
                value={old_pass}
                required={true}
                type={show_old_pass ? "input" : "password"}
                placeholder="Current Password"
                minLength={4}
              />
              <i
                className={show_old_pass ? "fa fa-eye-slash" : "fa fa-eye"}
                onClick={() => {
                  showOldPass(!show_old_pass);
                }}
              ></i>
            </label>

            <label className="password">
              <input
                className="loginInput"
                onChange={(e) => {
                  setNewPass(e.target.value.trim());
                }}
                value={new_pass}
                required={true}
                type={show_new_pass ? "input" : "password"}
                placeholder="New Password"
                minLength={4}
              />
              <i
                className={show_new_pass ? "fa fa-eye-slash" : "fa fa-eye"}
                onClick={() => {
                  showNewPass(!show_new_pass);
                }}
              ></i>
            </label>

            <label className="password">
              <input
                className="loginInput"
                onChange={(e) => {
                  setConfirmPass(e.target.value.trim());
                }}
                value={confirm_pass}
                required={true}
                type={show_confirm_pass ? "input" : "password"}
                placeholder="Retype New Password"
                minLength={4}
              />
              <i
                className={show_confirm_pass ? "fa fa-eye-slash" : "fa fa-eye"}
                onClick={() => {
                  showConfirmPass(!show_confirm_pass);
                }}
              ></i>
            </label>

            <div className="buttonContainer">
              <button
                className="cancel"
                onClick={clearState}
                disabled={disabled}
                style={{ opacity: disabled ? 0.5 : 1, color: "#9225e0" }}
              >
                Close
              </button>

              <button
                className="ok"
                onClick={validate}
                disabled={disabled}
                style={{
                  opacity: disabled ? 0.5 : 1,
                  backgroundColor: "#9225e0",
                }}
              >
                Change Password
              </button>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
function mapStateToProps(state) {
  const { auth } = state;
  return {
    access_token: auth.access_token,
    user_id: auth.user_id,
    student_auth: auth.student_auth,
    user_info: auth.user_info,
  };
}

export default connect(mapStateToProps)(ChangePassword);
