import React, { Component } from "react";
import OfferTag from "../../assets/discount.png";
import { connect } from "react-redux";
import { colors } from "../../css/colors";
import { imageURL } from "../../utils/constants";

class ProductItem extends Component {
  addToCart = (item) => {
    this.props.dispatch({
      type: "ADD_TO_CART",
      payload: {
        id: [item.id, ...this.props.cart],
      },
    });
  };

  removeFromCart = (item) => {
    this.props.dispatch({
      type: "REMOVE_FROM_CART",
      payload: {
        id: item.id,
      },
    });
  };

  render() {
    const { item, index, cart, user_id } = this.props;
    return (
      <>
        <div className="prodDiv">
          {item.tag && <h6 className="tag">{item.tag}</h6>}
          <img
            src={
              item.photo_url && item.photo_url !== ""
                ? item.photo_url
                : imageURL
            }
            alt="Product thumbnail"
          />

          <div className="productContent">
            <h4>{item.title}</h4>

            <h6>
              {item.is_paid == "N"
                ? "Free"
                : (item.country_details && item.country_details.currency_code
                    ? item.country_details.currency_code == "INR"
                      ? "₹"
                      : item.country_details.currency_code
                    : "") +
                  " " +
                  item.price}
              {item.discount_percentage > 0 && (
                <span className="discount">
                  {item.country_details && item.country_details.currency_code
                    ? item.country_details.currency_code == "INR"
                      ? "₹"
                      : item.country_details.currency_code
                    : ""}{" "}
                  {item.price_before_discount}
                </span>
              )}
              {item.discount_percentage > 0 && (
                <span
                  style={{ color: "green", fontWeight: 700, paddingLeft: 6 }}
                >
                  {" "}
                  {item.discount_percentage} % Off{" "}
                </span>
              )}
            </h6>

            <div style={{ display: "flex", flexDirection: "row" }}>
              {item.offer_available && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginRight: 10,
                  }}
                >
                  <img
                    src={OfferTag}
                    style={{ height: 14, width: 14, marginRight: 4 }}
                  />
                  <h5 style={{ color: colors.CRUSTA, fontSize: 12 }}>
                    Offers Available
                  </h5>
                </div>
              )}

              {item.purchase_count_status !== 0 &&
                item.total_prod_purchaseed > 0 && (
                  <h5 style={{ color: colors.PICTON_BLUE, fontSize: 12 }}>
                    {item.total_prod_purchaseed} Student(s) Purchased
                  </h5>
                )}
            </div>
            {/* <p dangerouslySetInnerHTML={{ __html: item.about.length > 80 ? (item.about.slice(0, 80) + '...') : item.about }} ></p> */}

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                position: "sticky",
                bottom: 0,
              }}
            >
              {cart && cart.includes(item.id) && !item.is_enrolled && (
                <button
                  style={{
                    marginRight: 4,
                    color: colors.CARDINAL,
                    borderColor: colors.CARDINAL,
                  }}
                  onClick={() => this.removeFromCart(item)}
                >
                  Remove from Cart <i className="fa fa-remove"></i>
                </button>
              )}

              {(!cart || (cart && !cart.includes(item.id))) &&
                item.remaining_products > 0 &&
                !item.is_enrolled && (
                  <button
                    style={{ marginRight: 4 }}
                    onClick={() => this.addToCart(item)}
                  >
                    Add to Cart <i className="fa fa-shopping-cart"></i>
                  </button>
                )}

              {item.remaining_products == 0 && !item.is_enrolled && (
                <button
                  disabled={true}
                  style={{
                    marginRight: 4,
                    color: colors.CRUSTA,
                    borderColor: colors.CRUSTA,
                    fontWeight: 700,
                    borderWidth: 0,
                    paddingLeft: 0,
                  }}
                >
                  Sold Out
                </button>
              )}

              {item.is_enrolled && (
                <button
                  disabled={true}
                  style={{
                    marginRight: 4,
                    color: colors.JADE,
                    borderColor: colors.JADE,
                    fontWeight: 700,
                    borderWidth: 0,
                    paddingLeft: 0,
                  }}
                >
                  <i className="fa fa-check" style={{ padding: 0 }}></i>{" "}
                  Purchased
                </button>
              )}

              <button
                style={{ marginLeft: 8 }}
                onClick={this.props.onClickView}
              >
                View <i className="fa fa-angle-right"></i>
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return { ...state.cart, ...state.auth };
};

export default connect(mapStateToProps)(ProductItem);
