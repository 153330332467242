import React, { Component } from "react";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import HELPERS from "../../utils/helper";
import apis from "../../config/apis";
import { colors } from "../../css/colors";
import CONFIG from "../../config";
import { ToastMessage, Toast, toastMsgConfig } from "../../components/toast";
import Error, { ErrorMessageHandling } from "../../components/error";
import ProductDesc from "./productDesc";
import { imageURL } from "../../utils/constants";

class MyOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      success: false,
      orders: [],
      show_product_desc: false,
      selected_item: {},
    };
  }

  componentDidMount = () => {
    this.getMyOrders();
  };

  getMyOrders = async () => {
    const { user_id, student_auth, institute_id } = this.props;
    let headers = {
      headers: {
        "x-prod-user-id": user_id,
        "x-prod-inst-id": institute_id,
        Authorization: student_auth,
      },
    };
    try {
      let res = await HELPERS.ApiCall(
        apis.MY_ORDER,
        {},
        "GET",
        "product",
        headers
      );
      if (res.status == 200 && res.data.status_code == 200) {
        this.setState({
          loader: false,
          orders: res.data.result,
          success: true,
        });
      } else {
        this.message("e", res.data.message);
        this.setState({ loader: false, success: false });
      }
    } catch (error) {
      if (error.response && error.response.status == 403) {
        this.props.dispatch({ type: "LOGOUT" });
      } else if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        Array.isArray(error.response.data.error) &&
        error.response.data.error.length > 0
      ) {
        this.message("e", error.response.data.error[0].error_message);
      } else {
        this.message("e", ErrorMessageHandling(error));
      }
      this.setState({ loader: false, success: false });
    }
  };

  message = (type, message) => {
    Toast(message, toastMsgConfig(type));
  };

  render() {
    const { loader, orders, success, show_product_desc, selected_item } =
      this.state;
    return (
      <>
        <ToastMessage />
        {loader && (
          <div className="content">
            <div className="dashboardLoader"></div>
          </div>
        )}
        {!loader && success && orders.length > 0 && (
          <div className="content bgimg2">
            <div
              className="portalMainContent"
              style={{ paddingTop: 40, width: "90%" }}
            >
              <h2>My Orders</h2>
              <div className="listContainer">
                {orders.map((item, i) => (
                  <div key={i} className="productDiv">
                    <img
                      src={
                        item.photo_url && item.photo_url !== ""
                          ? item.photo_url
                          : imageURL
                      }
                      style={{ height: 150, width: "100%" }}
                      alt="Product thumbnail"
                    />
                    <div className="productContent" style={{ height: 120 }}>
                      <h5 style={{ color: "#bb65f8" }}>{item.title}</h5>
                      <div className="productDates">
                        <div>
                          <h6 style={{ color: "gray" }}>Purchased Date</h6>
                          <h5>
                            {new Date(item.enrolled_date).toLocaleDateString()}
                          </h5>
                        </div>
                        <div>
                          <h6 style={{ color: "gray" }}>Expired Date</h6>
                          <h5>
                            {new Date(item.expire_on).toLocaleDateString()}
                          </h5>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <h6 style={{ color: colors.JADE }}>
                          {item.is_paid == "N"
                            ? "Free"
                            : item.price +
                              " " +
                              (item.country_details &&
                              item.country_details.currency_code
                                ? item.country_details.currency_code
                                : "")}
                        </h6>
                        <button
                          style={{ borderColor: "#bb65f8", color: "#bb65f8" }}
                          onClick={() => {
                            this.setState({
                              selected_item: item,
                              show_product_desc: true,
                            });
                          }}
                        >
                          View <i className="fa fa-angle-right"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        {!loader && success && orders.length == 0 && (
          <div
            className="myOrderEmptyCart bgimg2"
            style={{ height: window.innerHeight - 40 }}
          >
            <i
              className="fa fa-shopping-bag"
              style={{ fontSize: 60, marginBottom: 20 }}
            ></i>
            <h4>You haven't purchased any product yet!</h4>
            {/* <button onClick={() => {
                            this.props.history.push('/product')
                         }} >Buy Now</button> */}
          </div>
        )}
        {!loader && !success && (
          <Error onReload={this.getMyOrders} message={""} />
        )}

        {show_product_desc && (
          <ProductDesc
            show={show_product_desc}
            product={selected_item}
            handleClose={() => {
              this.setState({ show_product_desc: false });
            }}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return { ...state.auth };
};
export default connect(mapStateToProps)(withRouter(MyOrders));
