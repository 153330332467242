import Slider1 from "../../assets/slider1.png";
import Slider2 from "../../assets/slider2.png";
import Test1 from "../../assets/test1.jpeg";
import Test2 from "../../assets/test2.jpeg";
import Test3 from "../../assets/test3.jpeg";
import Test4 from "../../assets/test4.jpeg";
export const product_details = [
  {
    created_date: "2020-11-06T05:23:23.000+0000",
    updated_date: "2020-11-06T05:23:59.000+0000",
    entity_id: "0c91757f-6138-419f-9a04-4e16052bfa96",
    id: "0c91757f-6138-419f-9a04-4e16052bfa96",
    institute_id: 100058,
    is_active: "N",
    about: "<p>asfda</p>",
    duration: 3,
    gst: 18,
    is_advance_product: 1,
    is_paid: "Y",
    logo_url:
      "https://proctur.s3-ap-southeast-1.amazonaws.com/logos/Thumbnail+product.png",
    page_description: null,
    photo_url:
      "https://proctur.s3-ap-southeast-1.amazonaws.com/logos/Thumbnail+product.png",
    price: 90,
    product_ecourse_maps: [
      {
        institute_id: 100058,
        product_id: "0c91757f-6138-419f-9a04-4e16052bfa96",
        course_type_id: 365,
        course_type: "10 ssc online",
      },
    ],
    product_item_list: null,
    product_user_type: 8,
    publish_date: "2020-11-06T05:23:59.000+0000",
    purchase_limit: 3,
    remaining_products: 3,
    sales_from_date: "2020-11-06T00:00:00.000+0000",
    sales_to_date: "2020-11-25T00:00:00.000+0000",
    status: 30,
    title: "Other Country",
    valid_from_date: "2020-11-25T00:00:00.000+0000",
    valid_to_date: "2020-11-28T00:00:00.000+0000",
    start_index_for_total_prod_purchase: 0,
    discount_percentage: 10,
    price_before_discount: 100,
    offer_available: false,
    available_offers: [],
    total_prod_purchaseed: 0,
    tag: "Featured",
    product_sharable_link:
      "https://estoresit.proctur.com/product?proc=Other_Country#$0c91757f-6138-419f-9a04-4e16052bfa96",
    country_id: 10,
    enrolled_date: null,
    expired: null,
    expire_on: null,
    is_enrolled: false,
    public_order_id: null,
    country_details: {
      country_code: "AFG",
      currency_name: null,
      country_name: "Afghanistan",
      country_id: 10,
      currency_code: "AFN",
    },
    product_items_types: [
      {
        created_date: "2019-09-20T21:38:56.000+0000",
        updated_date: "2019-09-20T21:38:56.000+0000",
        entity_id: "fc196465-4383-4dc1-bc77-427b537543dd",
        name: "Online Test",
        slug: "Online_Test",
      },
    ],
  },
  {
    created_date: "2020-11-06T11:32:06.000+0000",
    updated_date: "2020-11-07T05:45:04.000+0000",
    entity_id: "10734f8c-9994-4386-96ba-0ff188b42e74",
    id: "10734f8c-9994-4386-96ba-0ff188b42e74",
    institute_id: 100058,
    is_active: "N",
    about: "<p>jhkjhjh</p>",
    duration: 88,
    gst: 18,
    is_advance_product: 0,
    is_paid: "Y",
    logo_url:
      "https://proctur.s3-ap-southeast-1.amazonaws.com/logos/Thumbnail+product.png",
    page_description: null,
    photo_url:
      "https://proctur.s3-ap-southeast-1.amazonaws.com/logos/Thumbnail+product.png",
    price: 15,
    product_ecourse_maps: [
      {
        institute_id: 100058,
        product_id: "10734f8c-9994-4386-96ba-0ff188b42e74",
        course_type_id: 410,
        course_type: "abcd",
      },
    ],
    product_item_list: null,
    product_user_type: 8,
    publish_date: "2020-11-06T11:32:29.000+0000",
    purchase_limit: 777,
    remaining_products: 775,
    sales_from_date: "2020-11-06T00:00:00.000+0000",
    sales_to_date: "2020-11-30T00:00:00.000+0000",
    status: 30,
    title: "yhujjj",
    valid_from_date: "2020-11-30T00:00:00.000+0000",
    valid_to_date: "2021-02-26T00:00:00.000+0000",
    start_index_for_total_prod_purchase: 8,
    discount_percentage: 66,
    price_before_discount: 44,
    offer_available: false,
    available_offers: [],
    total_prod_purchaseed: 10,
    tag: null,
    product_sharable_link:
      "https://estoresit.proctur.com/product?proc=yhujjj#$10734f8c-9994-4386-96ba-0ff188b42e74",
    country_id: 218,
    enrolled_date: "2020-11-07T05:45:04.000+0000",
    expired: false,
    expire_on: "2021-02-03T05:45:04.000+0000",
    is_enrolled: false,
    public_order_id: null,
    country_details: {
      country_code: "ARE",
      currency_name: null,
      country_name: "United Arab Emirates",
      country_id: 218,
      currency_code: "AED",
    },
    product_items_types: [
      {
        created_date: "2019-09-20T21:38:56.000+0000",
        updated_date: "2019-09-20T21:38:56.000+0000",
        entity_id: "89598b14-1567-4298-85b2-e07b2738bcac",
        name: "Study Material",
        slug: "Study_Material",
      },
    ],
  },
  {
    created_date: "2020-10-26T08:53:06.000+0000",
    updated_date: "2020-10-26T08:53:41.000+0000",
    entity_id: "2e8a75b3-e4d5-4a5a-8b04-966d7cb20c88",
    id: "2e8a75b3-e4d5-4a5a-8b04-966d7cb20c88",
    institute_id: 100058,
    is_active: "N",
    about: "<p>jvhjvkmb&nbsp;</p>",
    duration: 100,
    gst: 18,
    is_advance_product: 1,
    is_paid: "Y",
    logo_url:
      "https://proctur.s3-ap-southeast-1.amazonaws.com/logos/Thumbnail+product.png",
    page_description: null,
    photo_url:
      "https://proctur.s3-ap-southeast-1.amazonaws.com/logos/Thumbnail+product.png",
    price: 100,
    product_ecourse_maps: [
      {
        institute_id: 100058,
        product_id: "2e8a75b3-e4d5-4a5a-8b04-966d7cb20c88",
        course_type_id: 365,
        course_type: "10 ssc online",
      },
      {
        institute_id: 100058,
        product_id: "2e8a75b3-e4d5-4a5a-8b04-966d7cb20c88",
        course_type_id: 75,
        course_type: "Aniket course",
      },
      {
        institute_id: 100058,
        product_id: "2e8a75b3-e4d5-4a5a-8b04-966d7cb20c88",
        course_type_id: 410,
        course_type: "abcd",
      },
      {
        institute_id: 100058,
        product_id: "2e8a75b3-e4d5-4a5a-8b04-966d7cb20c88",
        course_type_id: 263,
        course_type: "Aniket Student notification testing master course",
      },
    ],
    product_item_list: null,
    product_user_type: 8,
    publish_date: "2020-10-26T08:53:41.000+0000",
    purchase_limit: 10,
    remaining_products: 10,
    sales_from_date: "2020-10-26T00:00:00.000+0000",
    sales_to_date: "2020-11-27T00:00:00.000+0000",
    status: 30,
    title: "tedddd",
    valid_from_date: "2020-11-27T00:00:00.000+0000",
    valid_to_date: "2021-03-07T00:00:00.000+0000",
    start_index_for_total_prod_purchase: 90,
    discount_percentage: 9,
    price_before_discount: 110,
    offer_available: true,
    available_offers: [],
    total_prod_purchaseed: 90,
    tag: "Recommended",
    product_sharable_link:
      "https://estoresit.proctur.com/product?proc=tedddd#$2e8a75b3-e4d5-4a5a-8b04-966d7cb20c88",
    country_id: 1,
    enrolled_date: null,
    expired: null,
    expire_on: null,
    is_enrolled: false,
    public_order_id: null,
    country_details: {
      country_code: "IND",
      currency_name: "Indian Rupee",
      country_name: "India",
      country_id: 1,
      currency_code: "INR",
    },
    product_items_types: [
      {
        created_date: "2019-09-20T21:38:56.000+0000",
        updated_date: "2019-09-21T21:38:56.000+0000",
        entity_id: "04ebe937-a8cc-49d9-abf1-4aeea7d15bac",
        name: "Video Lecture",
        slug: "Videos",
      },
    ],
  },
  {
    created_date: "2020-11-06T05:18:45.000+0000",
    updated_date: "2020-11-11T11:12:22.000+0000",
    entity_id: "3c63ffcd-3b27-4983-9fc1-7ed4daa9d60c",
    id: "3c63ffcd-3b27-4983-9fc1-7ed4daa9d60c",
    institute_id: 100058,
    is_active: "N",
    about: "<p>Hello</p>",
    duration: 4,
    gst: 18,
    is_advance_product: 1,
    is_paid: "Y",
    logo_url:
      "https://proctur.s3-ap-southeast-1.amazonaws.com/logos/Thumbnail+product.png",
    page_description: null,
    photo_url:
      "https://proctur.s3-ap-southeast-1.amazonaws.com/logos/Thumbnail+product.png",
    price: 90,
    product_ecourse_maps: [
      {
        institute_id: 100058,
        product_id: "3c63ffcd-3b27-4983-9fc1-7ed4daa9d60c",
        course_type_id: 410,
        course_type: "abcd",
      },
    ],
    product_item_list: null,
    product_user_type: 8,
    publish_date: "2020-11-06T05:19:44.000+0000",
    purchase_limit: 5,
    remaining_products: 5,
    sales_from_date: "2020-11-06T00:00:00.000+0000",
    sales_to_date: "2020-11-25T00:00:00.000+0000",
    status: 30,
    title: "Testing price",
    valid_from_date: "2020-11-25T00:00:00.000+0000",
    valid_to_date: "2020-11-29T00:00:00.000+0000",
    start_index_for_total_prod_purchase: 0,
    discount_percentage: 10,
    price_before_discount: 100,
    offer_available: true,
    available_offers: [],
    total_prod_purchaseed: 0,
    tag: "Featured",
    product_sharable_link:
      "https://estoresit.proctur.com/product?proc=Testing_price#$3c63ffcd-3b27-4983-9fc1-7ed4daa9d60c",
    country_id: 1,
    enrolled_date: null,
    expired: null,
    expire_on: null,
    is_enrolled: false,
    public_order_id: null,
    country_details: {
      country_code: "IND",
      currency_name: "Indian Rupee",
      country_name: "India",
      country_id: 1,
      currency_code: "INR",
    },
    product_items_types: [
      {
        created_date: "2019-09-20T21:38:55.000+0000",
        updated_date: "2019-09-20T21:38:55.000+0000",
        entity_id: "dda06a66-d59c-479a-a016-efe850973a2d",
        name: "Mock Test",
        slug: "Mock_Test",
      },
      {
        created_date: "2019-09-20T21:38:56.000+0000",
        updated_date: "2019-09-20T21:38:56.000+0000",
        entity_id: "fc196465-4383-4dc1-bc77-427b537543dd",
        name: "Online Test",
        slug: "Online_Test",
      },
    ],
  },
];

export const result = {
  basic_details: {
    favicon_url: "https://proctur.com/assets/proc_favicon-32x32.png",
    logo_url:
      "https://s3-ap-southeast-1.amazonaws.com/proctur/100127/logo2_75401.png",
    banner_url:
      "https://s3-ap-southeast-1.amazonaws.com/testeduimspro/website/100058/1605192653974-apiresponse.png",
    pop_up_image_url:
      "https://s3-ap-southeast-1.amazonaws.com/testeduimspro/website/100058/1605192654983-live_class_setting.png",
    copyright_url: "by Eduspace Technologies Pvt. Ltd.",
  },
  slider_details: [
    {
      id: 8,
      institute_id: 100058,
      image_url: Slider2,
      name: "COVID is not going to affect your children.",
      content:
        "COVID has affected the lives of everyone. Educational istitues are closed for the safety for your children. But we understand the importance of your children's time and can assure you that, this year is not going to be wasted. We are a complete digitized institute and along with online live classes we provide way more features like Study Material, Online Exam, Assignments, Notifications, etc. to ensure that nothing is missed.",
      order_number: 0,
    },
    {
      id: 7,
      institute_id: 100058,
      image_url: Slider1,
      name: "Ensuring quality education.",
      content:
        "Education is a tool to achieve your dreams and goals and we understand that education is the most powerful weapon to help bring the desired change in our society and to contribute to the society at large. We know that learning is not enought therefore provide a creative methodology to ensure the proper understanding of each and every concept",
      order_number: 0,
    },
  ],
  nav_links: [],
  testimonial_details: [
    {
      id: 10,
      institute_id: 100058,
      image_url: Test1,
      name: "Aditya Prajapati",
      content:
        "Their course helped me in giving appropriate practice for preparation for my exams and securing a good marks. The different concepts helped in developing skill and strategy. The online exams were helpful in giving good insight. I have already recommended this program to my juniors.",
      order_number: 0,
      rating: 4,
    },
    {
      id: 11,
      institute_id: 100058,
      image_url: Test2,
      name: "Amardeep Singh",
      content:
        "Here teachers are always available for help and clearing doubts. The way of teaching is helpful to cope with my subjects. I'll recommed their courses to everyone as you will be definitely benefitted by those.",
      order_number: 0,
      rating: 3.5,
    },
    {
      id: 12,
      institute_id: 100058,
      image_url: Test3,
      name: "Rohit Khanna",
      content:
        "Their courses have really been a base for my ambitions. It took care of all examinations that are important and provided adequate guidelines. It has been one of the best learning experiences for me. The faculty was kind enough to clear all the doubts and make the concepts crystal clear. ",
      order_number: 0,
      rating: 4,
    },
    {
      id: 13,
      institute_id: 100058,
      image_url: Test4,
      name: "Saumya Saxena",
      content:
        "Their courses have provided me the greatest learning experience in my life so far. Preparing for the main exams with their courses helped me to sharpen my logical and critical thinking ability which is very useful in life in general. A great deal of credit goes to them for whatever I have achieved.",
      order_number: 0,
      rating: 4.5,
    },
  ],
  about_us_details: {
    id: 0,
    institute_id: 100058,
    page_route: "/about-us",
    page_title: "About Us",
    in_navbar: true,
    page_data:
      "<p>We understand that education is the most powerful weapon to help bring the desired change in our society and to contribute to the society at large. So we were established with the thought of providing best quality education to the students in order to let them achieve their dreams. Started with a handful of students we reached admirable heights to become one of the best coaching institutes. The testimony to our excellence is that we are giving best results which can be cross checked from our stats. With our unique teaching methodology, we have been able to help the students realize and achieve their dreams. Student-centric approach and feedback-driven mechanism are the core pillars of our foundation. Providing quality education and a learning environment that extends education beyond the confines of traditional classroom learning.</p>",
    page_status: "PUBLISHED",
    banner_image_url: "",
  },
  social_details: {
    email_id: "proctur.a5@proctur.com",
    address: "234/42, 1st Floor, Street 4, Location, Pune PIN: 208080",
    contact: "56473578777",
    android_app_url:
      "https://play.google.com/store/apps/details?id=com.proctur.procturlearnstudent",
    ios_app_url: null,
    instagram_url: " https://www.facebook.com/proctur",
    linkedIn_url: " https://www.facebook.com/proctur",
    youtube_url: "https://www.youtube.com/channel/UCZAukirAW5WwY6PAbvdrMKg",
    twitter_url: null,
    whatsapp: "https://api.whatsapp.com/send?phone=919971839153",
    facebook_url: "https://www.facebook.com/proctur",
    form_url: null,
    blog_url: "djhfdk",
  },
  product_details: [],
  FAQs: [
    {
      id: 5,
      category_id: 1,
      category_name: "Student",
      question: "Why to choose us where other coaching are available?",
      answer:
        "We are happy you asked that as it gave us opportunity to introduce our qualities. The most important thing is way of teaching and student centic approach which makes us a game changer. After all you are not going to have your time back so don't waste it with some hit and trails, just simply go for the best.",
      order_number: 2,
    },
    {
      id: 23,
      category_id: 1,
      category_name: "Student",
      question: "Can i have some option of attending the classes online?",
      answer:
        "Absolutely, We have collaborated with Proctur: Your pocket classroom! to povide Learning Management System to us. And its one of the best in class solution available for online classes. Being most user-friendly and with hundreds of features you can have way more option than you used to have in your traditional offline classes.",
      order_number: 0,
    },
    {
      id: 24,
      category_id: 1,
      category_name: "Student",
      question: "Do you also provide online exams and mock tests for practice?",
      answer:
        "Yes, Using the same LMS we have created online exams and mock test for your practice. We even conduct quizes for the students so that they can check their progress and compare it with others.",
      order_number: 1,
    },
    {
      id: 25,
      category_id: 1,
      category_name: "Student",
      question:
        "How you are going to share study material with us in online mode?",
      answer:
        "Obviously we also have feature of sharing study material in the LMS. We'll share the study materials in multiple formats which will be accessible in student's application. Even we have the feature of assignments in the LMS.",
      order_number: 1,
    },
    {
      id: 6,
      category_id: 2,
      category_name: "Teacher",
      question: "Do you also provide live classes for students?",
      answer:
        "We understand that live class is the most important and required aspect of online classes and its the only way of interaction between students and teachers resembling offline classes. So we also provide a feature of Live classes using multiple options.",
      order_number: 0,
    },
    {
      id: 21,
      category_id: 2,
      category_name: "Teacher",
      question: "What if i've missed some live classes?",
      answer:
        "No issue as recording of live classes will also be available on our LMS after some time. So you can view that recording of the missed lecture. It like you are never going to miss any class.",
      order_number: 2,
    },
  ],
  course_details: [],
  theme_details: {
    font_family_heading_id: 1,
    background_color: null,
    text_content_color: null,
    font_family_content_name: "abc",
    font_family_content_id: 1,
    text_anchor_color: null,
    font_family_heading_name: "abc",
    border_color: null,
    text_heading_color: null,
  },
  third_party_integration_details: {
    chat: "dfjhksdh",
  },
};

export const faqs_api = {
  result: {
    "GENERAL FAQs": [...result.FAQs],
  },
};

const rp = `By refund amount we are referring to the amount paid by you for the purchase of any course from our site. We don't entertain any refund request in any case. If you do not found our courses satisfactory then you may unsubscribe or discontinue our services any time. In rare  situations we may consider your refund after certain discussions but we completely hold the right to go with any decision.`;

export const refund_policy = {
  banner_image_url: "",
  id: 0,
  in_navbar: true,
  institute_id: 100058,
  page_data: rp,
  page_route: "/terms-and-condition",
  page_status: "PUBLISHED",
  page_title: "Refund Policy",
};
