const initialState = {
  cart: []
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case 'ADD_TO_CART': {
      return { ...state, cart: action.payload.id };
    }
    case 'REMOVE_FROM_CART': {
      let cart = state.cart;
      return { ...state, cart: cart.filter(id => id !== action.payload.id) }
    }
    case 'EMPTY_CART': {
      return { ...state, cart: [] };
    }
    default: {
      return state;
    }
  }
}

export default reducer;
