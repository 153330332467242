export const colors = {
    BLUE: "#0675b5",
    WHITE: "#ffffff",
    BLACK: "#000000",
    RED: "#EC4C4C",
    PURPUL: '#8b76f7',
    YELLOW: '#ECE14C',
    LIGHTBLUE: '#88c8ea',
    GRAY: '#646464',
    LIGHTGRAY: "#f7f7f7",
    PINK: '#FFD7D8',
    BROWN: '#BC3438',
    ALABASTER: '#f8f8f8',
    SILVER: '#f7f7f7',
    MANATEE: '#9898a3',
    JADE: '#00b55a',
    TEXAS_ROSE: '#FFB951',
    PICTON_BLUE: '#4CC7E5',
    DODGERBLUE: '#2E70F2',
    CRUSTA: '#FF7E40',
    ROSE: '#FF007F',
    ELECTRICVIOLET: "#8700ff",
    CRIMSON: '#DF0D2F',
    CYAN: '#21D2FF',
    PSYCHEDELICPURPLE: '#EF03F8',
    SUNGLOW: '#ffd831',
    ISLAMIC_GREEN: '#15c402',
    REDVIOLET: '#df0d8b',
    SCREAMIN_GREEN: '#81FA5F',
    PASTEL_GREEN: '#4fe24d',
    CARDINAL: '#B91A3E',
    LEMON: '#F0C80C',
    PEARL: '#fabe3b'
}