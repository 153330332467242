import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";
import { siteRoutes, dashboardRoutes, otherRoutes } from "./allRoutes";
import { connect } from "react-redux";
import Home from "./home";
import Policies from "./policies";

function ParamsExample(props) {
  useEffect(() => {
    props.dispatch({
      type: "SET_LOADING_STATE",
      loading_first_time: true,
    });
  }, []);

  function PrivateRoute({ children, ...rest }) {
    return (
      <Route
        {...rest}
        render={({ location }) =>
          props.auth.user_id ? (
            children
          ) : (
            <Redirect to={{ pathname: "/login", state: { from: location } }} />
          )
        }
      />
    );
  }

  return (
    <Router>
      <Switch>
        {siteRoutes.map((routes, i) => {
          let current_url = window.location.href;
          if (!props.auth.institute_id && routes.path !== "/") {
            return (
              <Redirect
                key={i}
                to={{ pathname: "/", state: { current_url } }}
              />
            );
          } else {
            return (
              <Route
                key={i}
                path={routes.path}
                exact={routes.exact}
                strict={routes.strict}
                children={<routes.component />}
              />
            );
          }
        })}
        <Route path={"*"} component={Policies} />
        <PrivateRoute path="/dashboard">
          <Switch>
            {dashboardRoutes.map((routes, i) => (
              <Route
                key={i}
                path={routes.path}
                exact={routes.exact}
                strict={routes.strict}
                children={<routes.component />}
              />
            ))}
          </Switch>
        </PrivateRoute>
      </Switch>
    </Router>
  );
}

const mapStateToProps = (state) => {
  return state;
};
const App = connect(mapStateToProps)(ParamsExample);
export default App;
