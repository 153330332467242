import React, { useState } from "react";
import { messages } from "./constant";
import HELPERS from "../../utils/helper";
import apis from "../../config/apis";
import { connect } from "react-redux";
import { ToastMessage, Toast, toastMsgConfig } from "../../components/toast";

const Forgot = ({ show, handleClose, access_token }) => {
  const showHideClassName = show ? "alert display-block" : "alert display-none";
  const [mobile_number, setNumber] = useState("");
  const [loader, setLoader] = useState(false);

  async function callForgotAPI() {
    let headers = {
      headers: { Authorization: access_token.third_party_auth_token },
    };
    let payload = {
      user_name: mobile_number,
      institute_id: access_token.institute_id,
    };
    try {
      let res = await HELPERS.ApiCall(
        apis.FORGOT_PASSWORD,
        payload,
        "POST",
        "proctur",
        headers
      );
      if (res.data.statusCode) {
        message("s", "New Password send to your registered mobile number.");
        setNumber("");
        handleClose();
      } else {
        message(
          "e",
          res.data.message
            ? res.data.message
            : "Something went wrong. Please try after sometime."
        );
      }
      setLoader(false);
    } catch (error) {
      if (error.response) {
        message("e", error.response.data.error[0].errorMessage);
      } else {
        message("e", error.message);
      }
      setLoader(false);
    }
  }

  async function message(type, message) {
    Toast(message, toastMsgConfig(type));
  }

  return (
    <>
      {loader && <div className="loading"></div>}
      <ToastMessage />
      <div className={showHideClassName}>
        <section className="alert-main forgotBlock">
          <h4>Forgot Password</h4>
          <p>{messages.forgot_password}</p>

          <label className="inputLabel" style={{ fontSize: 12 }}>
            Mobile Number:
            <input
              className="loginInput"
              value={mobile_number}
              placeholder="Enter Mobile Number"
              onChange={(e) => {
                const re = /^[0-9\b]+$/;
                if (e.target.value === "" || re.test(e.target.value)) {
                  setNumber(e.target.value);
                }
              }}
            />
          </label>

          <div className="buttonContainer">
            <button
              className="cancel"
              onClick={() => {
                setNumber("");
                handleClose();
              }}
            >
              Close
            </button>
            <button
              className="ok"
              disabled={mobile_number.trim().length < 1}
              style={{ opacity: mobile_number.trim().length < 1 ? 0.5 : 1 }}
              onClick={() => {
                setLoader(true);
                callForgotAPI();
              }}
            >
              Continue
            </button>
          </div>
        </section>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  const { auth } = state;
  return { access_token: auth.access_token };
}

export default connect(mapStateToProps)(Forgot);
