import React, { Component } from 'react'
import Header from '../../components/header';
import Footer from '../../components/footer';
import { connect } from 'react-redux';
import { social_links, returnLinks } from '../home';
import HELPERS from '../../utils/helper';
import { ToastMessage, Toast, toastMsgConfig, toastTypes } from '../../components/toast';
import { messages } from '../auth/constant';
import apis from '../../config/apis';
import CONFIG from '../../config';
import { ErrorMessageHandling } from '../../components/error';


class ContactUs extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      success: false,
      mobile_number: '',
      email: '',
      message: '',
      full_name: '',
      disabled: false
    };
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
  }

  message = (type, message) => {
    Toast(message, toastMsgConfig(type));
  }

  validate = () => {
    this.setState({ disabled: true })
    const { full_name, email, mobile_number, message } = this.state;

    if (full_name.trim().length === 0) {
      this.message('e', messages.invalid_name_length);
      this.setState({ disabled: false });
    } else if (mobile_number.trim().length === 0) {
      this.message('e', messages.invalid_number_length);
      this.setState({ disabled: false });
    } else if (email.trim().length === 0) {
      this.message('e', messages.invalid_email_length);
      this.setState({ disabled: false });
    } else if (!HELPERS.validateEmail(email)) {
      this.message('e', messages.invalid_email);
      this.setState({ disabled: false });
    } else if (message.trim().length === 0) {
      this.message('e', 'Message field cannot be empty');
      this.setState({ disabled: false });
    } else {
      this.setState({ loader: true })
      this.sendMessage();
    }
  }

  sendMessage = async () => {
    const { full_name, email, mobile_number, message } = this.state;
    try {
      let headers = { headers: { 'Authorization': this.props.access_token.third_party_auth_token } }
      let payload = {
        name: full_name,
        mobile: mobile_number,
        email: email,
        comments: message,
        institute_id: this.props.institute_id,
        customComponentList: []
      }
      let res = await HELPERS.ApiCall(apis.SEND_MESSAGE, payload, 'POST', 'proctur', headers);
      if (res.status == 200 && res.data.statusCode == 200) {
        this.setState({ loader: false, full_name: '', mobile_number: '', email: '', message: '', disabled: false });
        this.message('s', 'Message send successfully!');
      } else {
        this.message('e', res.data.message);
        this.setState({ loader: false, success: false, disabled: false })
      }
    } catch (err) {
      if (err.response && err.response.status == 403) {
        this.props.dispatch({ type: 'LOGOUT', message: err.response.data && err.response.data.message ? err.response.data.message : '' });
      } else if (err && err.response && err.response.message) {
        this.message('e', err.response.message);
      } else {
        this.message('e', ErrorMessageHandling(err));
      }
      this.setState({ loader: false, success: false, disabled: false })
    }
  }

  render() {
    const { data } = this.props;
    const { message, email, mobile_number, full_name, disabled, loader } = this.state;
    return (
      <>
        {loader &&
          <div className="loading"></div>
        }
        <ToastMessage />
        <Header />
        <div className="content bgimg2" style={{ paddingTop: 70 }}>
          <div className="mainContent" style={{ width: '80%', height: window.innerHeight - 200 }} >
            <h2>Contact Us</h2>

            <div className="contactpage">

              <div className="contactpage-left" style={{ width: this.props.site_data.lead == true ? '44%' : '100%' }} >

                {data.social_details.address && data.social_details.address !== '' &&
                  <div className="contact-content" >
                    <div className="icon" onClick={() => { window.open(`https://maps.google.com/?q=${data.social_details.address}`, '_blank') }} >
                      <i className="fa fa-map-marker"></i>
                    </div>
                    <div className="data" >
                      <h5>Address</h5>
                      <a className="contact-link" href={`https://maps.google.com/?q=${data.social_details.address}`} target={"_blank"} >{data.social_details.address}</a>
                    </div>
                  </div>
                }

                {data.social_details.email_id && data.social_details.email_id != "" &&
                  <div className="contact-content" >
                    <div className="icon" onClick={() => { window.open(`mailto: ${data.social_details.email_id}`) }} >
                      <i className="fa fa-envelope-o mr4"></i>
                    </div>
                    <div className="data" >
                      <h5>Email</h5>
                      <a className="contact-link" href={`mailto: ${data.social_details.email_id}`}>{data.social_details.email_id}</a>
                    </div>
                  </div>
                }

                {data.social_details.contact && data.social_details.contact !== '' &&
                  <div className="contact-content" >
                    <div className="icon" onClick={() => { window.open(`tel:${data.social_details.contact}`, '_self') }} >
                      <i className="fa fa-phone mr4"></i>
                    </div>
                    <div className="data" >
                      <h5>Phone</h5>
                      <a className="contact-link" href={`tel:${data.social_details.contact}`}>{data.social_details.contact} </a>
                    </div>
                  </div>
                }

                <div className="social-link" >
                  {social_links.map((item, index) => (
                    returnLinks(item, data.social_details, index)
                  ))}
                </div>

              </div>

              {this.props.site_data.lead == true &&
                <div className="contactpage-right" >
                  <h3>Submit Your Query</h3>

                  <input className="loginInput"
                    value={full_name}
                    type="text"
                    placeholder="Full Name"
                    onChange={(e) => {
                      if (HELPERS.validateName(e.target.value)) {
                        this.setState({ full_name: e.target.value })
                      }
                    }}
                  />

                  <input className="loginInput"
                    value={mobile_number}
                    placeholder="Mobile Number"
                    onChange={(e) => {
                      const re = /^[0-9\b]+$/;
                      if (e.target.value === '' || re.test(e.target.value)) {
                        this.setState({ mobile_number: e.target.value })
                      }
                    }}
                  />

                  <input className="loginInput"
                    value={email}
                    placeholder="Email"
                    type="email"
                    onChange={(e) => {
                      this.setState({ email: e.target.value })
                    }}
                  />

                  <textarea className="loginInput"
                    style={{ height: 60, padding: 10 }}
                    value={message}
                    placeholder="Message"
                    onChange={(e) => {
                      this.setState({ message: e.target.value })
                    }}
                    rows="5" />

                  <button className="ok" disabled={disabled}
                    onClick={this.validate}
                    style={{ padding: '6px 20px', marginTop: 10, opacity: disabled ? 0.5 : 1 }}
                  >Send</button>

                </div>
              }

            </div>

          </div>
        </div>
        <Footer />
      </>
    )
  }
}

const mapStateToProps = state => { return { ...state.home, ...state.auth }; };
export default connect(mapStateToProps)(ContactUs);
