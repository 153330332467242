import React, { Component } from 'react'
import Header from './header';
import Footer from './footer';
import { withRouter } from 'react-router-dom';
import PaymentFailure from '../assets/paymentFailure.png'

class Fail extends Component {

    render() { 
        return (
            <>
                <Header />
                <div className="thankYou" style={{ height: window.innerHeight - 200 }} >

                    <div className="thankYou-tick" style={{ backgroundColor: '#ce4639' }} >
                        <i className="fa fa-close" ></i>
                    </div>
                    <h2 style={{ color: '#ce4639' }} >OOPS!</h2>
                    <h3>Something went wrong at the time of purchasing product.</h3>

                    <button onClick={() => { this.props.history.push("/cart") }} style={{ backgroundColor: '#ce4639' }}  ><i className='fa fa-refresh' ></i> Try again</button>

                </div>
                <Footer />
            </>
        )
    }
}

export default withRouter(Fail);
