import React, { useState, useEffect } from "react";

const MultiInstitute = ({ show, handleClose, user_role_list, login }) => {
  const showHideClassName = show ? "alert display-block" : "alert display-none";
  const [flags, setFlag] = useState([]);
  const [selected, setItem] = useState({});

  useEffect(() => {
    let temp = Array(user_role_list.length).fill(false);
    if (temp.length > 0) {
      temp[0] = true;
      setItem(user_role_list[0]);
    }
    setFlag(temp);
  }, []);

  return (
    <>
      <div className={showHideClassName}>
        <section className="alert-main">
          <h4>Select Institute</h4>

          {user_role_list.map((item, i) => (
            <div
              key={i}
              style={{
                display: "flex",
                flexDirection: "row",
                margin: 10,
                opacity: 0.6,
              }}
              onClick={() => {
                setItem(item);
                let itemFlag = flags;
                for (let index = 0; index < itemFlag.length; index++) {
                  itemFlag[index] = !itemFlag[index];
                }
                setFlag(itemFlag);
              }}
            >
              <i
                className={flags[i] ? "fa fa-dot-circle-o" : "fa fa-circle-o"}
              ></i>
              <div>
                <h6 style={{ paddingLeft: 10 }}>{item.institute_name}</h6>
                {/* {item.user_type == 5 && */}
                <h6 style={{ paddingLeft: 10 }}>
                  {"Student Name :  " + item.name}
                </h6>
                {/* } */}
              </div>
            </div>
          ))}

          <div className="buttonContainer">
            <button onClick={handleClose} className="cancel">
              Close
            </button>
            <button
              onClick={() => {
                handleClose();
                login(selected);
              }}
              className="ok"
            >
              OK
            </button>
          </div>
        </section>
      </div>
    </>
  );
};

export default MultiInstitute;
