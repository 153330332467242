import React, { Component } from "react";
import "../../css/styles.css";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { Slider } from "./slider";
import CourseItem from "../courses/courseItem";
import ProductItem from "../products/productItem";
import Testimonials from "./testimonials";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { item_list_responsive } from "../../utils/constants";
import { Link, withRouter } from "react-router-dom";
import FAQsItem from "./faqsItem";
import FAQsImage from "../../assets/faq.png";
import HELPERS from "../../utils/helper";
import apis from "../../config/apis";
import { connect } from "react-redux";
import CONFIG, {
  default_institute_id,
  default_institute_id_name,
} from "../../config";
import { LongDescription } from "../courses/longDescription";
import PopUpImage from "./popUpImage";
import ErrorPage, { ErrorMessageHandling } from "../../components/error";
import {
  ToastMessage,
  Toast,
  toastMsgConfig,
  toastTypes,
} from "../../components/toast";
import { result } from "./data";
import { fixed_policies } from "../policies";

export const social_links = [
  "whatsapp",
  "facebook_url",
  "linkedIn_url",
  "instagram_url",
  "youtube_url",
  "twitter_url",
];
export const social_links_icons = {
  facebook_url: "fa fa-facebook",
  twitter_url: "fa fa-twitter",
  linkedIn_url: "fa fa-linkedin",
  instagram_url: "fa fa-instagram",
  youtube_url: "fa fa-youtube",
  whatsapp: "fa fa-whatsapp",
};

export const returnLinks = (tag, item, i) => {
  if (item[tag] && item[tag] !== "") {
    return (
      <a
        key={i}
        href={
          tag == "whatsapp"
            ? `//api.whatsapp.com/send?phone=${item[tag]}`
            : item[tag]
        }
        target={"_blank"}
      >
        <i className={social_links_icons[tag]}></i>
      </a>
    );
  } else {
    return null;
  }
};

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      loader: true,
      show_long_desc: false,
      selected_item: {},
      open_pop_up: false,
      pop_up_image_url: "",
      load_data: true,
    };
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0);
    const { location } = this.props;
    let current_url =
      location && location.state && location.state.current_url
        ? location.state.current_url
        : window.location.href;
    if (current_url.includes("proc=")) {
      current_url = current_url.split("?");
      let prod_data = current_url.length > 1 ? current_url[1].split("#$") : [];
      let title = prod_data.length > 1 ? prod_data[0] : "";
      title = title.split("proc=");
      title = title.length > 1 ? title[1] : "";
      if (title.includes("&fbclid=")) {
        title = title.split("&fbclid=");
        title = title[0];
      }
      let id = prod_data.length > 1 ? prod_data[1] : "";
      let ins_id = prod_data.length > 2 ? prod_data[2] : "";
      this.getInstituteId(window.location.href, "product", title, id, ins_id);
    } else if (current_url.includes("/more/")) {
      this.getInstituteId(window.location.href, "more");
    } else {
      let url = window.location.origin;
      url = url + (url[url.length - 1] == "/" ? "" : "/");
      this.getInstituteId(url, "none");
    }
  };

  tempSetId = () => {
    this.props.dispatch({
      type: "SET_INSTITUTE_ID",
      institute_id: default_institute_id,
    });
    this.props.dispatch({
      type: "SET_INSTITUTE_NAME",
      institute_name: default_institute_id_name,
      website_enable: true,
    });
    document.title = default_institute_id_name;
    this.setDetails(this.props.data);
    this.getWebAccessToken();
    this.getHomePageData();
  };

  getInstituteId = async (url, tag, t, i, ins_id) => {
    try {
      let res = await HELPERS.ApiCall(
        apis.GET_INSTITUTE_ID + url.replace("www.", ""),
        {},
        "GET",
        "proctur",
        {}
      );

      if (res.status == 200 && res.data && res.data.institute_id) {
        this.props.dispatch({
          type: "SET_INSTITUTE_ID",
          institute_id: res.data.institute_id,
        });
        await this.props.dispatch({
          type: "SET_INSTITUTE_NAME",
          institute_name: res.data.institute_name,
          website_enable:
            res.data.website_enable == undefined ||
            res.data.website_enable == null
              ? true
              : res.data.website_enable,
        });
        await this.props.dispatch({
          type: "SET_SITE_DATA",
          site_data: res.data,
        });
        document.title = res.data.institute_name;
        await this.getWebAccessToken();

        if (tag == "product") {
          if (res.data.website_enable) {
            this.setDetails(this.props.data);
            this.getHomePageData();
          }
          this.navigateToProductDesc(i, t, ins_id);
        } else if (tag == "more") {
          if (res.data.website_enable) {
            this.setDetails(this.props.data);
            this.getHomePageData();
          }
          this.navigateToMorePage();
        } else {
          if (
            res.data.website_enable !== undefined &&
            res.data.website_enable !== null &&
            res.data.website_enable == false
          ) {
            if (res.data.fav_icon_url) {
              const favicon = document.getElementById("favicon");
              favicon.href = res.data.fav_icon_url;
            }
            this.props.history.push("/product");
          } else {
            this.setDetails(this.props.data);
            this.getHomePageData();
          }
        }
      } else if (res.status == 200 && res.data.statusCode == 200) {
        this.tempSetId();
        if (tag == "product") {
          this.navigateToProductDesc(i, t, ins_id);
        } else if (tag == "more") {
          this.navigateToMorePage();
        }
      } else {
        this.tempSetId();
        if (tag == "product") {
          this.navigateToProductDesc(i, t, ins_id);
        } else if (tag == "more") {
          this.navigateToMorePage();
        }
      }
    } catch (err) {
      if (err && err.response && err.response.message) {
        this.message("e", err.response.message);
      } else {
        this.message("e", ErrorMessageHandling(err));
      }
      this.setState({ load_data: false, loader: false });
    }
  };

  navigateToProductDesc = (i, t, ins_id) => {
    let obj = {
      id: i,
      title: t,
      api_param: `${t.replace("/", "")}#$${i}#$${ins_id}`,
      from_direct_url: true,
    };
    this.props.history.push({
      pathname: `/product?proc=${t.replace("/", "")}#$${i}#$${ins_id}`,
      state: { item: obj, ins_id: ins_id },
    });
  };

  navigateToMorePage = () => {
    const { location } = this.props;
    let url =
      location && location.state && location.state.current_url
        ? location.state.current_url
        : window.location.href;
    url = url.split("/");
    let page = url[url.length - 1];
    let slug = "/" + page;
    this.props.history.push({
      pathname: `/more${slug}`,
      state: {
        slug: fixed_policies.includes(slug) ? slug : page,
        hide_image: fixed_policies.includes(slug),
      },
    });
  };

  setDetails = (data, flag) => {
    if (data && data.basic_details) {
      const favicon = document.getElementById("favicon");
      favicon.href = data.basic_details.favicon_url;
    }
    if (
      data &&
      data.theme_details &&
      data.theme_details.text_heading_color &&
      data.theme_details.text_heading_color !== ""
    ) {
      document.documentElement.style.setProperty(
        "--theme-color",
        data.theme_details.text_heading_color
      );
    }
    if (
      data &&
      data.theme_details &&
      data.theme_details.text_content_color &&
      data.theme_details.text_content_color !== ""
    ) {
      document.documentElement.style.setProperty(
        "--text-color",
        data.theme_details.text_content_color
      );
    }
  };

  getHomePageData = async () => {
    try {
      let res = await HELPERS.ApiCall(
        apis.HOMEPAGE + this.props.institute_id,
        {},
        "GET",
        "product",
        {}
      );

      if (res.status == 200 && res.data.status_code == 200) {
        this.props.dispatch({
          type: "HOME_PAGE_DATA",
          data: res.data.result,
        });

        this.setState({ loader: false, data: res.data.result });
        this.setDetails(res.data.result, true);
      } else {
        this.message("e", res.data.message);
        this.setState({ loader: false });
      }
    } catch (error) {
      if (error.response && error.response.status == 403) {
        this.props.dispatch({
          type: "LOGOUT",
          message:
            error.response.data && error.response.data.message
              ? error.response.data.message
              : "",
        });
      } else if (error.response && error.response.message) {
        this.message("e", error.response.message);
      } else {
        this.message("e", ErrorMessageHandling(error));
      }
      this.setState({ loader: false });
    }
  };

  getWebAccessToken = async () => {
    let res = await HELPERS.ApiCall(
      apis.WEB_ACCESS_TOKEN + this.props.institute_id,
      {},
      "GET",
      "proctur",
      {}
    );
    if (res.status == 202 && res.data.statusCode == 200) {
      this.props.dispatch({
        type: "SET_ACCESS_TOKEN",
        access_token: res.data.result,
      });
      this.props.dispatch({
        type: "UPDATE_REGISTRATION_INSTITUTE",
        data: res.data.result,
      });
    }
  };

  message = (type, message) => {
    Toast(message, toastMsgConfig(type));
  };

  componentDidUpdate(prevProps) {
    let currentProps = this.props;
    let flag =
      currentProps &&
      currentProps.data &&
      currentProps.data.basic_details &&
      currentProps.data.basic_details.pop_up_image_url &&
      currentProps.data.basic_details.pop_up_image_url !== "";
    let flag1 =
      prevProps &&
      prevProps.data &&
      prevProps.data.basic_details &&
      prevProps.data.basic_details.pop_up_image_url;
    if (
      flag &&
      flag1 &&
      prevProps.data.basic_details.pop_up_image_url !==
        currentProps.data.basic_details.pop_up_image_url
    ) {
      this.setState({
        open_pop_up: true,
        pop_up_image_url: currentProps.data.basic_details.pop_up_image_url,
      });
    } else if (flag && !flag1) {
      this.setState({
        open_pop_up: true,
        pop_up_image_url: currentProps.data.basic_details.pop_up_image_url,
      });
    } else if (this.props.loading_first_time && flag) {
      this.setState({
        open_pop_up: true,
        pop_up_image_url: currentProps.data.basic_details.pop_up_image_url,
      });
      this.props.dispatch({
        type: "SET_LOADING_STATE",
        loading_first_time: false,
      });
    }
  }

  render() {
    const { loader, show_long_desc, selected_item, load_data } = this.state;
    const { data, site_data } = this.props;
    return (
      <>
        <ToastMessage />
        {loader && <div className="loading"></div>}
        {data && data.course_details && load_data && (
          <>
            <Header />

            <div style={{ paddingTop: 70, zIndex: 5, overflow: "auto" }}>
              <Slider
                slider_details={
                  data.slider_details.length > 0
                    ? data.slider_details
                    : result.slider_details
                }
              />

              {/* <div className="content bgimg">
                <div className='about-us-home' >
                  <h2>ABOUT US</h2>
                  <div style={{ display: 'flex', margin: '40px 0px' }} >
                    {data && data.about_us_details && data.about_us_details.page_data &&
                      <div dangerouslySetInnerHTML={{ __html: data.about_us_details.page_data.length > 400 ? (data.about_us_details.page_data.slice(0, 400) + '...') : data.about_us_details.page_data }} ></div>
                    }

                    {(!data || !data.about_us_details || !data.about_us_details.page_data) &&
                      <div dangerouslySetInnerHTML={{ __html: result.about_us_details.page_data.length > 400 ? (result.about_us_details.page_data.slice(0, 400) + '...') : result.about_us_details.page_data }} ></div>
                    }
                  </div>
                  <Link className="more" to={"/about"}>VIEW  MORE <i className="fa fa-arrow-right" ></i> </Link>
                </div>
              </div> */}

              {data.course_details.length > 0 && !site_data.school_model && (
                <div className="content bgcolor">
                  <div className="mainContent" style={{ width: "84%" }}>
                    <h2>POPULAR COURSES</h2>
                    <Carousel
                      containerClass="coursesContainer"
                      responsive={item_list_responsive}
                      autoPlay={true}
                      infinite={true}
                      autoPlaySpeed={7000}
                      showDots={true}
                      arrows={false}
                    >
                      {data.course_details.map((item, index) => (
                        <CourseItem
                          key={index}
                          item={item}
                          index={index}
                          showLongDesc={() => {
                            this.setState({
                              show_long_desc: true,
                              selected_item: item,
                            });
                            // document.body.style.overflow = 'hidden';
                          }}
                        />
                      ))}
                    </Carousel>
                    <Link className="more" to={"/courses"}>
                      VIEW ALL COURSES <i className="fa fa-arrow-right"></i>
                    </Link>
                  </div>
                </div>
              )}

              {data.product_details.length > 0 &&
                site_data &&
                site_data.enable_elearn_feature &&
                !site_data.school_model && (
                  <div className="content bgimg2">
                    <div className="mainContent" style={{ width: "84%" }}>
                      <h2>POPULAR PRODUCTS</h2>
                      <Carousel
                        containerClass="productContainer"
                        responsive={item_list_responsive}
                        autoPlay={true}
                        infinite={true}
                        autoPlaySpeed={7000}
                        focusOnSelect={true}
                        showDots={true}
                        arrows={false}
                      >
                        {data.product_details.map((item, index) => (
                          <ProductItem
                            key={index}
                            item={item}
                            index={index}
                            onClickView={() => {
                              let temp = item;
                              let link = item.product_sharable_link.split("#$");
                              temp["api_param"] = `${item.title.replace(
                                "/",
                                ""
                              )}#$${item.id}#$${link[link.length - 1]}`;
                              temp["from_direct_url"] = false;
                              this.props.history.push({
                                pathname: `/product?proc=${item.title.replace(
                                  "/",
                                  ""
                                )}#$${item.id}#$${link[link.length - 1]}`,
                                state: {
                                  item: temp,
                                  ins_id: link[link.length - 1],
                                },
                              });
                            }}
                          />
                        ))}
                      </Carousel>
                      <Link className="more" to={"/product"}>
                        VIEW ALL PRODUCTS <i className="fa fa-arrow-right"></i>
                      </Link>
                    </div>
                  </div>
                )}

              <div className="content bgcolor2">
                <div className="mainContent" style={{ width: "84%" }}>
                  <h2>FAQs</h2>
                  <div className="faqContainer">
                    <FAQsItem
                      faqs={data.FAQs.length > 0 ? data.FAQs : result.FAQs}
                    />
                    <div className="faqImg">
                      <img src={FAQsImage} />
                    </div>
                  </div>
                  <br clear="all" />
                  <br />
                  <br />
                  <Link className="more" to={"/faqs"}>
                    VIEW ALL FAQs <i className="fa fa-arrow-right"></i>
                  </Link>
                </div>
              </div>

              <Testimonials
                testimonials={
                  data.testimonial_details.length > 0
                    ? data.testimonial_details
                    : result.testimonial_details
                }
              />

              <Footer />
            </div>
          </>
        )}
        {!loader && !load_data && (
          <div
            className="noNetwork bgimg2"
            style={{ height: window.innerHeight }}
          >
            <h3>
              Unable to load website. Please try again or Contact your
              institute.
            </h3>
          </div>
        )}

        {!loader && !data.course_details && load_data && (
          <ErrorPage
            onReload={() => {
              this.getHomePageData();
              this.setState({ loader: true });
            }}
            message=""
          />
        )}

        <LongDescription
          show={show_long_desc}
          item={selected_item}
          onClose={() => {
            this.setState({ show_long_desc: false });
            // document.body.style.overflow = 'unset';
          }}
        />
        <PopUpImage
          handleClose={() => {
            this.setState({ open_pop_up: false });
            // document.body.style.overflow = 'unset';
          }}
          image={this.state.pop_up_image_url}
          show={this.state.open_pop_up}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return { ...state.home, ...state.auth };
};
export default connect(mapStateToProps)(withRouter(Home));
