import React from 'react';

const Alert = ({ handleClose, handleOk, show, heading, content, hideClose, link }) => {
    const showHideClassName = show ? "alert display-block" : "alert display-none";
    return (
        <div className={showHideClassName}>
            <section className="alert-main" style={{ maxHeight: window.innerHeight - 200 }} >
                {heading !== '' &&
                    <h4>{heading}</h4>
                }
                <p style={{ color: getComputedStyle(document.documentElement).getPropertyValue("--text-color") }} >{content}</p>
                {link &&
                    <a style={{ fontSize: 12 }} href={link} target={'_blank'}  >{link}</a>
                }
                <div className="buttonContainer" >
                    {!hideClose &&
                        <button onClick={handleClose} className="cancel">Close</button>
                    }
                    <button onClick={handleOk} className="ok">OK</button>
                </div>
            </section>
        </div>
    );
};

export default Alert;
