import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const Toast = toast;
export const toastTypes = {
    e: 'error',
    i: 'info',
    s: 'success',
    w: 'warning',
    default: 'default'
}

export const toastMsgConfig = (type) => {
    let config = {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    }
    config['type'] = toastTypes[type];
    return config;
}


export const ToastMessage = () => {
    return (
        <ToastContainer
            position="top-right"
            autoClose={3000}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            closeButton={false}
        />
    )
}