import React, { Component } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import ProductItem from "./productItem";
import HELPERS from "../../utils/helper";
import apis from "../../config/apis";
import { withRouter } from "react-router-dom";
import ErrorPage, { ErrorMessageHandling } from "../../components/error";
import CONFIG from "../../config";
import { connect } from "react-redux";
import {
  ToastMessage,
  Toast,
  toastMsgConfig,
  toastTypes,
} from "../../components/toast";

class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      loader: true,
      success: false,
    };
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
    let current_url = window.location.href;
    if (current_url.includes("proc=")) {
      current_url = current_url.split("?");
      let prod_data = current_url.length > 1 ? current_url[1].split("#$") : [];
      let title = prod_data.length > 1 ? prod_data[0] : "";
      title = title.split("proc=");
      title = title.length > 1 ? title[1] : "";
      if (title.includes("&fbclid=")) {
        title = title.split("&fbclid=");
        title = title[0];
      }
      let id = prod_data.length > 1 ? prod_data[1] : "";
      let ins_id = prod_data.length > 2 ? prod_data[2] : "";
      let obj = {
        id: id,
        title: title,
        api_param: `${title.replace("/", "")}#$${id}#$${ins_id}`,
        from_direct_url: true,
      };
      this.props.history.push({
        pathname: `/product?proc=${title.replace("/", "")}#$${id}#$${ins_id}`,
        state: { item: obj, ins_id: ins_id },
      });
    } else {
      this.getAllProducts();
    }
  };

  message = (type, message) => {
    Toast(message, toastMsgConfig(type));
  };

  getAllProducts = async () => {
    const { user_id, access_token, student_auth, institute_id } = this.props;
    let headers = {
      headers: {
        "x-prod-user-id": user_id == 0 ? "" : user_id,
        "x-prod-inst-id": institute_id,
        Authorization: user_id == 0 ? access_token.auth_token : student_auth,
      },
    };
    let payload = {
      by: [{ column: "itemTypeSlug", value: "" }],
      in: [{ column: "eCourseName", values: [] }],
    };
    try {
      let res = await HELPERS.ApiCall(
        apis.PRODUCT_LIST,
        payload,
        "POST",
        "product",
        headers
      );
      if (res.status == 200 && res.data.status_code == 200) {
        console.warn("res 123 : ", res.data.result);
        this.setState({
          loader: false,
          products: res.data.result,
          success: true,
        });
      } else {
        this.setState({ loader: false, success: false });
        this.message("e", res.data.message);
      }
    } catch (error) {
      if (error.response && error.response.status == 403) {
        this.props.dispatch({
          type: "LOGOUT",
          message:
            error.response.data && error.response.data.message
              ? error.response.data.message
              : "",
        });
      } else if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        Array.isArray(error.response.data.error) &&
        error.response.data.error.length > 0
      ) {
        this.message("e", error.response.data.error[0].error_message);
      } else {
        this.message("e", ErrorMessageHandling(error));
      }
      this.setState({ loader: false, success: false });
    }
  };

  render() {
    const { loader, products, success } = this.state;
    return (
      <>
        <ToastMessage />

        <Header />

        {loader && (
          <div className="content">
            <div className="loading"></div>
          </div>
        )}

        {!loader && success && products.length > 0 && (
          <div className="content bgimg2" style={{ paddingTop: 70 }}>
            <div className="mainContent" style={{ width: "92%" }}>
              <h2>POPULAR PRODUCTS</h2>
              <div className="listContainer">
                {products.map((item, index) => (
                  <ProductItem
                    key={index}
                    item={item}
                    index={index}
                    onClickView={() => {
                      let temp = item;
                      let link = item.product_sharable_link.split("#$");
                      temp["api_param"] = `${item.title.replace("/", "")}#$${
                        item.id
                      }#$${link[link.length - 1]}`;
                      temp["from_direct_url"] = false;
                      this.props.history.push({
                        pathname: `/product?proc=${item.title.replace(
                          "/",
                          ""
                        )}#$${item.id}#$${link[link.length - 1]}`,
                        state: { item: temp, ins_id: link[link.length - 1] },
                      });
                    }}
                  />
                ))}
              </div>
            </div>
          </div>
        )}

        {!loader && success && products.length == 0 && (
          <div
            className="emptyCart"
            style={{ height: window.innerHeight - 200, paddingTop: 70 }}
          >
            <i
              className="fa fa-dropbox"
              style={{ marginBottom: 30, fontSize: 100 }}
            ></i>
            <h4>Products not available!</h4>
          </div>
        )}

        {!loader && !success && (
          <ErrorPage
            onReload={() => {
              this.setState({ loader: true });
              this.getAllProducts();
            }}
            message={""}
          />
        )}

        <Footer />
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return { ...state.auth };
};

export default connect(mapStateToProps)(withRouter(Products));
