import React, { useState, useEffect } from "react";
import BannerImage from "../../assets/banner.png";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { slider_responsive } from "../../utils/constants";
import { useHistory } from "react-router-dom";

export const Slider = (props) => {
  const [loading, setLoader] = useState(true);
  const [data, setData] = useState();
  const history = useHistory();
  useEffect(() => {
    setData(props.slider_details);
    setLoader(false);
  }, []);

  function Navigate(to) {
    history.push(to);
  }

  return (
    <>
      {!loading && (
        <Carousel
          className="slider"
          responsive={slider_responsive}
          autoPlay={true}
          infinite={true}
          autoPlaySpeed={7000}
          focusOnSelect={true}
        >
          {data.map((item, index) => (
            <div key={index} className="slide">
              <div className="sliderImgDiv">
                <img
                  src={item.image_url}
                  className="sliderBanner"
                  alt="Slider image"
                />
              </div>
              <div className="slideTextDiv">
                <h2>{item.name}</h2>
                <p dangerouslySetInnerHTML={{ __html: item.content }}></p>
              </div>
            </div>
          ))}
        </Carousel>
      )}
    </>
  );
};
