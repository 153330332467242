import React, { Component } from "react";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import HELPERS from "../../utils/helper";
import apis from "../../config/apis";
import { colors } from "../../css/colors";
import CONFIG from "../../config";
import { ToastMessage, Toast, toastMsgConfig } from "../../components/toast";
import Error, { ErrorMessageHandling } from "../../components/error";
import Recordings from "./recordings";
import { downloadVideoFile } from "../../services/awsVideo";

class LiveClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      success: false,
      classes: [],
    };
  }

  componentDidMount = () => {
    this.getLiveClasses();
    downloadVideoFile();
  };

  message = (type, message) => {
    Toast(message, toastMsgConfig(type));
  };

  getLiveClasses = async () => {
    const { user_id, student_auth, institute_id } = this.props;
    let headers = { headers: { Authorization: student_auth } };

    try {
      let res = await HELPERS.ApiCall(
        apis.LIVE_CLASS + institute_id,
        { user_id: user_id },
        "POST",
        "proctur",
        headers
      );
      if (res.status == 200) {
        this.setState({ loader: false, success: true, classes: res.data });
      }
    } catch (error) {
      if (error.response && error.response.status == 403) {
        this.props.dispatch({ type: "LOGOUT" });
      } else {
        this.message("e", ErrorMessageHandling(error));
      }
      this.setState({ loader: false, success: false });
    }
  };

  displayIframe = (item) => {
    document.getElementById(
      "iframeDisplay"
    ).innerHTML = `<iframe src="https://d1z78r8i505acl.cloudfront.net/playerAssets/1.6.10/vdo/embed/index.html#otp=20160313versASE323YqlmINWywWhALE348AdutkWpBiTSgwjD8u490nuTmiLehp&playbackInfo=eyJ2aWRlb0lkIjoiMDA3MWI1ZGRiMzA5NDE4MWI0ODgzNjM2NjBiNDA4NDIifQ==" width="320" height="240" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>            `;
  };

  render() {
    const { loader, success, classes } = this.state;
    return (
      <>
        <ToastMessage />
        {loader && (
          <div className="content">
            <div className="dashboardLoader"></div>
          </div>
        )}
        {/* <a href='/somefile.txt' download>Click to download</a> */}

        {/* {!loader && success &&
                    classes.pastLiveClasses && classes.pastLiveClasses.map((item, i) => (
                        <div>
                            {item.download_links.length > 0 &&
                                <div>
                                    <h6>{item.session_name},  index : {i}</h6>
                                    <button onClick={this.displayIframe} >Play</button>
                                    <div id="iframeDisplay"></div>
                                </div>
                            }
                        </div>
                    ))
                } */}

        {!loader && !success && (
          <Error onReload={this.getLiveClasses} message={""} />
        )}
        {/* <Recordings show={true} /> */}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return { ...state.auth };
};
export default connect(mapStateToProps)(withRouter(LiveClass));
