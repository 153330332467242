import React, { useState } from "react";

const Error = ({ onReload, message }) => {
  const [disabled, setDisabled] = useState(false);
  return (
    <div className="emptyCart" style={{ height: window.innerHeight - 200 }}>
      <i
        className="fa fa-exclamation-triangle"
        style={{ fontSize: 60, color: "red", marginBottom: 20 }}
      ></i>
      <h4>Something went wrong! Please try after some time.</h4>
      <button
        disabled={disabled}
        onClick={() => {
          setDisabled(true);
          onReload();
          setTimeout(() => {
            setDisabled(false);
          }, 3000);
        }}
      >
        <i className="fa fa-refresh" style={{ marginRight: 4 }}></i>Reload
      </button>
    </div>
  );
};

export default Error;

export const ErrorMessageHandling = (error) => {
  if (error.response && error.response.data && error.response.data.message) {
    return error.response.data.message;
  } else if (error.response && error.response.status) {
    switch (error.response.status) {
      case 400:
        return "Bad Request";
      case 401:
        return "Unauthorized";
      case 404:
        return "Not Found";
      case 500:
        return "Internal Server Error";
      case 503:
        return "Service Unavailable";
      default:
        return "Something went wrong! Please try after sometime.";
    }
  } else {
    return "Please check your internet connection!";
  }
};
