const initialState = {
    data: {},
    site_data: {},
    loading_first_time: false
};

function reducer(state = initialState, action) {
    switch (action.type) {
        case 'HOME_PAGE_DATA': {
            return { ...state, data: action.data };
        }
        case 'SET_SITE_DATA': {
            let enable_elearn_feature = (action.site_data.enable_elearn_feature == true || action.site_data.store == true) ? true : (action.site_data.enable_elearn_feature == true && action.site_data.store == true) ? true : false;
            return { ...state, site_data: { ...action.site_data, enable_elearn_feature: enable_elearn_feature } }
        }
        case 'SET_LOADING_STATE': {
            return { ...state, loading_first_time: action.loading_first_time }
        }
        default: {
            return state;
        }
    }
}

export default reducer;
