import React, { Component } from "react";
import HELPERS from "../../utils/helper";
import apis from "../../config/apis";
import CONFIG from "../../config";
import { ToastMessage, Toast, toastMsgConfig } from "../../components/toast";
import Error, { ErrorMessageHandling } from "../../components/error";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  privacy_policy,
  refund_policy,
  terms_and_condition,
} from "../home/data";
import { PrivacyPolicy, TermsandCondition } from "./policy";
export let fixed_policies = [
  "/privacy-policy",
  "/refund-policy",
  "/terms-and-condition",
];

class ClientPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      success: false,
      data: "",
      slug: "",
    };
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
    let slug = this.getSlug();
    this.setState({ slug: slug });
    this.getPageData(slug);
  };

  getSlug = () => {
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.slug
    ) {
      return this.props.location.state.slug;
    } else {
      let url = window.location.href;
      url = url.split("/");
      if (fixed_policies.includes("/" + url[url.length - 1])) {
        return "/" + url[url.length - 1];
      } else {
        return url[url.length - 1];
      }
    }
  };

  message = (type, message) => {
    Toast(message, toastMsgConfig(type));
  };

  getPageData = async (slug) => {
    try {
      let res = await HELPERS.ApiCall(
        apis.GET_CLIENT_PAGE_DATA + this.props.institute_id + `?slug=${slug}`,
        {},
        "GET",
        "product",
        {}
      );
      if (res.status == 200 && res.data.status_code == 200) {
        this.setState({
          loader: false,
          success: true,
          data: res.data.result,
        });
      } else {
        this.message("e", res.data.message);
        this.setState({ loader: false, success: false });
      }
    } catch (error) {
      if (
        error.response &&
        error.response.status == 400 &&
        error?.response?.data.error[0]?.error_message
      ) {
        this.message("e", error?.response?.data.error[0]?.error_message);
        this.setState({ loader: false, success: true });
      } else if (error.response && error.response.status == 403) {
        this.props.dispatch({
          type: "LOGOUT",
          message:
            error.response.data && error.response.data.message
              ? error.response.data.message
              : "",
        });
      } else {
        this.message("e", ErrorMessageHandling(error));
        this.setState({ loader: false, success: true });
      }
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      window.scrollTo(0, 0);
      this.setState({ loader: true, success: false });
    }
  }

  render() {
    const { loader, success, data } = this.state;
    let policy = ["/privacy-policy", "/refund-policy", "/terms-and-condition"];
    let slug = this.getSlug();

    let hide_image =
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.hide_image
        ? this.props.location.state.hide_image
        : fixed_policies.includes(slug)
        ? true
        : false;
    return (
      <>
        <ToastMessage />
        <Header />
        <div className="content bgimg" style={{ paddingTop: 70 }}>
          {loader && (
            <div className="content">
              <div className="loading"></div>
            </div>
          )}

          {!hide_image && !loader && success && data.banner_image_url && (
            <div className="about-us">
              <h2 className="about-us-heading">
                {data && data.page_title ? data.page_title : ""}
              </h2>

              <img src={data.banner_image_url} alt="Banner Image" />

              <div className="about-us-data">
                <h2 style={{ marginBottom: 30 }}>
                  {data && data.page_title ? data.page_title : ""}
                </h2>

                <div dangerouslySetInnerHTML={{ __html: data.page_data }} />

                {!data.page_data && (
                  <p>Looks like details are not available!</p>
                )}
              </div>
            </div>
          )}

          {!loader &&
            success &&
            (hide_image || (!hide_image && !data.banner_image_url)) &&
            (data.page_data || (!data.page_data && !policy.includes(slug))) && (
              <div className="policies">
                <h2 style={{ marginBottom: 30 }}>
                  {data && data.page_title ? data.page_title : ""}
                </h2>
                <div
                  style={{ display: "flex", margin: "40px 0px", width: "100%" }}
                >
                  <div dangerouslySetInnerHTML={{ __html: data.page_data }} />
                  {!data.page_data && !policy.includes(slug) && (
                    <p> Looks like details are not available!</p>
                  )}
                </div>
              </div>
            )}
          {!data.page_data &&
            policy.includes(slug) &&
            slug == "/privacy-policy" && (
              <div className="policies">
                <h2 style={{ marginBottom: 30 }}>
                  {data && data.page_title ? data.page_title : "Privacy Policy"}
                </h2>
                <PrivacyPolicy />
              </div>
            )}
          {!data.page_data &&
            policy.includes(slug) &&
            slug == "/refund-policy" && (
              <div className="policies">
                <h2 style={{ marginBottom: 30 }}>
                  {data && data.page_title ? data.page_title : "Refund Policy"}
                </h2>
                <div
                  dangerouslySetInnerHTML={{ __html: refund_policy.page_data }}
                />
              </div>
            )}

          {!data.page_data &&
            policy.includes(slug) &&
            slug == "/terms-and-condition" && (
              <div className="policies">
                <h2 style={{ marginBottom: 30 }}>
                  {data && data.page_title
                    ? data.page_title
                    : "Terms & Conditions"}
                </h2>
                <TermsandCondition />
              </div>
            )}

          {!loader && !success && (
            <Error onReload={this.getPageData} message={""} />
          )}
        </div>

        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return { ...state.auth };
};

export default connect(mapStateToProps)(withRouter(ClientPage));
