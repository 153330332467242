import React, { Component } from 'react'
import Header from '../../components/header';
import Footer from '../../components/footer';
import CourseItem from './courseItem';
import HELPERS from '../../utils/helper';
import apis from '../../config/apis';
import CONFIG from '../../config';
import { ToastMessage, Toast, toastMsgConfig, toastTypes } from '../../components/toast';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { LongDescription } from './longDescription';
import ErrorPage, { ErrorMessageHandling } from '../../components/error';

class Courses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      courses: [],
      loader: true,
      success: false,
      show_long_desc: false,
      selected_item: {}
    };
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
    this.getCourses();
  }

  getCourses = async () => {
    this.setState({ loader: true })

    let headers = { headers: { Authorization: this.props.user_id == 0 ? this.props.access_token.auth_token : this.props.student_auth } }

    try {
      let res = await HELPERS.ApiCall(apis.GET_COURSES + this.props.institute_id + "?data_for_registeration=true", {}, 'GET', 'proctur', headers);
      if (res.status == 200) {
        this.setState({ courses: res.data, loader: false, success: true })
      }
    } catch (error) {
      if (error.response && error.response.status == 403) {
        this.props.dispatch({ type: 'LOGOUT', message: error.response.data && error.response.data.message ? error.response.data.message : '' });
      } else {
        this.message('e', ErrorMessageHandling(error));
      }
      this.setState({ loader: false, success: false })
    }
  }

  message = (type, message) => {
    Toast(message, toastMsgConfig(type));
  }

  render() {
    const { loader, courses, success, show_long_desc, selected_item } = this.state;
    return (
      <>
        <LongDescription show={show_long_desc}
          item={selected_item}
          onClose={() => {
            this.setState({ show_long_desc: false });
            // document.body.style.overflow = 'unset';
          }} />
        <ToastMessage />
        <Header />

        {loader &&
          <div className="content">
            <div className="loading"></div>
          </div>
        }

        {!loader && success && courses.length > 0 &&
          <div className="content bgimg2" style={{ paddingTop: 70 }}>
            <div className="mainContent" style={{ width: '92%' }} >
              <h2>COURSES</h2>
              <div className="listContainer">
                {courses.map((item, index) => (
                  <CourseItem key={index} item={item} index={index} showLongDesc={() => {
                    this.setState({ show_long_desc: true, selected_item: item });
                    // document.body.style.overflow = 'hidden';
                  }} />
                ))}
              </div>
            </div>
          </div>
        }

        {!loader && success && courses.length == 0 &&
          <div className="emptyCart" style={{ height: window.innerHeight - 200, paddingTop: 70 }} >
            <i className="fa fa-book" style={{ marginBottom: 30, fontSize: 100 }} ></i>
            <h4>Courses not available!</h4>
          </div>
        }


        {!loader && !success &&
          <ErrorPage onReload={this.getCourses} message={""} />
        }
        <Footer />
      </>
    )
  }
}

const mapStateToProps = state => { return { ...state.auth }; };
export default connect(mapStateToProps)(withRouter(Courses));
