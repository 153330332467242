const initialState = {
  bgColor: '#FFF',
  headingColor: '#222',
  textColor: '#333',
  anchorColor: '#069',
  borderColor: '#EAEAEA',
  fontHeading: 'Poppins',
  fontText: 'Poppins',
  timestamp: 0,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case 'UPDATE_THEME': {
        return { ...action.payload };
    }
    default: {
        return state;
    }
  }
}

export default reducer;
