import liveClass from './sidebar/classes.png';
import home from './sidebar/home.png';
import onlineExam from './sidebar/online-exam.png';
import study from './sidebar/study.png';
import estore from './sidebar/estore.png';
import order from './sidebar/order.png';
import homeWebsite from './sidebar/website.png';
import offlineExam from './sidebar/offline-exam.png';
import documents from './sidebar/documents.png';
import timetable from './sidebar/timetable.png';
import myOrder from './order-white.png';
import user from './user.png';

export const dashboard = {
    home: home,
    live: liveClass,
    onlineExam: onlineExam,
    study: study,
    estore: estore,
    order: order,
    homeWebsite: homeWebsite,
    offlineExam:offlineExam,
    documents:documents,
    timetable:timetable,
    myOrder:myOrder,
    user:user
}