export const messages = {
  invalid_number: "Please enter a valid phone number",
  invalid_number_length: "Phone number field cannot be empty",
  invalid_pass: "Please enter valid password",
  invalid_pass_length: "New Password must be between 6-10 characters.",
  invalid_name_length: "Name field cannot be empty",
  invalid_email_length: "Email field cannot be empty",
  invalid_email: "You have entered an invalid email address!",
  invalid_confirm_pass: "Password and confirm password does not match",

  login_not_allowed:
    "You are not allowed to login from this application, Please contact your admin!",
  invalid_login_details: "Please enter correct login details!",
  renew_session:
    "You are already logged in on another device. Do you want to use here? You will be logged out from other devices.",
  not_registered_device:
    "This is not your registered device for login kindly use a registered device. For any query please contact your administrator.",

  forgot_password:
    "We're all a little forgetful sometimes. Just give us the mobile number you signed up with and we'll get you a new password.",

  otp_generation_failed:
    "Something went wrong at the time of generation OTP, try again!",
  otp_validation_failed:
    "Something went wrong at the time of otp validation. Try after some time.",
  register_success: "Registration successfull!",
  register_fail:
    "Something went wrong at the time of registration. Try after some time.",
};
