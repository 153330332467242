import React from "react";

const PopUpImage = ({ handleClose, show, image }) => {
  const showHideClassName = show ? "alert display-block" : "alert display-none";
  return (
    <div className={showHideClassName}>
      <section
        className="alert-main"
        style={{
          maxWidth: window.innerWidth - 100,
          maxHeight: window.innerHeight - 150,
          border: "1px solid #eee",
          display: "flex",
          flexDirection: "column",
          top: 80,
          paddingBottom: 26,
          paddingTop: 0,
        }}
      >
        <i
          onClick={handleClose}
          style={{
            alignSelf: "flex-end",
            color: "red",
            fontSize: 16,
            paddingTop: 6,
            marginRight: -4,
          }}
          className="fa fa-close"
        ></i>
        <img
          src={image}
          style={{
            width: "90%",
            alignSelf: "center",
            height: "auto",
            paddingTop: 6,
          }}
          alt="Pop up image"
        />
      </section>
    </div>
  );
};

export default PopUpImage;
