import React, { Component } from "react";

export const PrivacyPolicy = () => {
  return (
    <div
      style={{
        border: "1px solid #f1f1f1",
        backgroundColor: "#fff",
        textAlign: "left",
      }}
    >
      <ul>
        <li className="list1">GENERAL </li>
        <ul className="list1-ul">
          <li className="list2">
            We are committed to protecting and respecting your privacy. We do
            collect your personal information and process your personal data in
            accordance with the IT Act, 2000 (21 of 2000) and other national and
            state laws which relate the processing of personal data. Please read
            the following carefully to understand our views and practices
            regarding your personal data.{" "}
          </li>
          <li className="list2">
            We collect your personal information in order to provide and
            continually improve our products and services.{" "}
          </li>
          <li className="list2">
            Our privacy policy is subject to change at any time without notice.
            To make sure you are aware of any changes, please review this policy
            periodically.{" "}
          </li>
          <li className="list2">
            All partner firms and any third-party working with or for Us, and
            who have access to personal information, will be expected to read
            and comply with this policy.{" "}
          </li>
        </ul>

        <li className="list1">HOW WE COLLECT THE INFORMATION</li>
        <ul className="list1-ul">
          <li className="list2">
            From you directly and through this Site: We may collect information
            through the Website when you visit. The data we collect depends on
            the context of your interactions with our Website.{" "}
          </li>
          <li className="list2">
            Through business interaction: We may collect information through
            business interaction with you or your employees.{" "}
          </li>
          <li className="list2">
            From other sources: We may receive information from other sources,
            such as public databases; joint marketing partners; social media
            platforms; or other third parties such as:{" "}
          </li>
          <ul className="list2-ul">
            <li className="list3">
              Updated delivery and address information from our carriers or
              other third parties, which we use to correct our records and
              deliver your next purchase or communication more easily.{" "}
            </li>
            <li className="list3">
              Information about your interactions with the products and services
              offered by our subsidiaries.{" "}
            </li>
          </ul>
        </ul>

        <li className="list1"> INFORMATION WE COLLECT </li>
        <ul className="list1-ul">
          <li className="list2">
            We collect information primarily to provide better services to all
            of our customers.
          </li>
          <li className="list2">
            When you visit our Site, some information is automatically
            collected. This may include information such as the Operating
            Systems (OS) running on your device, Internet Protocol (IP) address,
            access times, browser type, and language, and the website you
            visited before our Site. We also collect information about how you
            use Our products or services.
          </li>
          <li className="list2">
            We automatically collect purchase or content use history, which we
            sometimes aggregate with similar information from other customers to
            create features such as Best Seller, Top Rated, etc...
          </li>
          <li className="list2">
            The full Uniform Resource Locators (URL) clickstream to, through and
            from our website (including date and time); cookie number; products
            and/or content you viewed or searched for; page response times;
            download errors; length of visits to certain pages; page interaction
            information (such as scrolling, clicks, and mouse-overs).
          </li>
          <li className="list2">
            {" "}
            We automatically collect information using "Cookies". Cookies are
            small data files stored on your hard drive the websites. Among other
            things, cookies help us improve our Site, our marketing activities,
            and your experience. We use cookies to see which areas and features
            are popular and to count visits to our Site.
          </li>
          <li className="list2">
            {" "}
            Most Web browsers are set to accept cookies by default. If you
            prefer, you can choose to set your browser to remove cookies and to
            reject cookies. If you set your browser to reject cookies, some
            features will be unavailable. For more information on how to reject
            cookies, see your browser's instructions on changing your cookie
            settings.
          </li>
          <li className="list2">
            {" "}
            By using this Website, you are agreeing that We may advertise your
            feedback on the Website and marketing materials.
          </li>
          <li className="list2">
            {" "}
            We will retain your information as long as we require this to
            provide you with the goods and services and for such period as
            mandated by the concerned laws.
          </li>
          <li className="list2">
            {" "}
            If you opt to receive marketing correspondence from us, subscribe to
            our mailing list or newsletters, enter into any of our competitions
            or provide us with your details at networking events, we may use
            your personal data for our legitimate interests in order to provide
            you with details about our goods, services, business updates and
            events.
          </li>
        </ul>

        <li className="list1"> HOW WE USE INFORMATION </li>
        <ul className="list1-ul">
          <li className="list2">
            {" "}
            We use the information we collect primarily to provide, maintain,
            protect and improve our current products and services.
          </li>
          <li className="list2">
            We use the information collected through this website as described
            in this policy and we may use your information to:
          </li>
          <ul className="list2-ul">
            <li className="list3">
              Improve our services, Site and how we operate our businesses;
            </li>
            <li className="list3">
              Understand and enhance your experience using our Site, products
              and services;
            </li>
            <li className="list3">
              Personalize our products or services and make recommendations;
            </li>
            <li className="list3">
              Provide and deliver products and services you request;
            </li>
            <li className="list3">
              Process, manage, complete, and account for transactions;
            </li>
            <li className="list3">
              Provide customer support and respond to your requests, comments,
              and inquiries;
            </li>
            <li className="list3">
              Create and manage the online accounts you manage on our Website;
            </li>
            <li className="list3">
              Send you related information, including confirmations, invoices,
              technical notices, updates, security alerts and support and
              administrative messages;
            </li>
            <li className="list3">
              Communicate with you about promotions, upcoming events and news
              about products and services;
            </li>
            <li className="list3">
              We may process your personal information without your knowledge or
              consent where required by applicable law or regulation for the
              purposes of verification of identity or for prevention, detection
              or investigation, including of cyber incidents, prosecution and
              punishment of offences;
            </li>
            <li className="list3">
              Protect, investigate and deter against fraudulent, unauthorized or
              illegal activity.
            </li>
          </ul>
        </ul>

        <li className="list1"> DATA TRANSFER </li>
        <ul className="list1-ul">
          <li className="list2">
            Information about our user is an important part of our business and
            we take due care to protect the same.
          </li>
          <li className="list2">
            We share your data with your consent or to complete any transaction
            or provide any product or service you have requested or authorized.
            We also share data with our affiliates and subsidiaries, with
            vendors working on our behalf.
          </li>
          <li className="list2">
            We may employ other companies and individuals to perform functions
            on our behalf. The functions include fulfilling orders for products
            or services, delivering packages, sending postal mail and e-mail,
            removing repetitive information from customer lists, providing
            marketing assistance, providing search results and links (including
            paid listings and links), processing payments, transmitting content,
            scoring credit risk, and providing customer service.
          </li>
          <li className="list2">
            These third-party service providers have access to personal
            information needed to perform their functions but may not use it for
            other purposes. Further, they must process the personal information
            in accordance with this Privacy Policy and as permitted by
            applicable data protection laws.
          </li>
          <li className="list2">
            We release account and other personal information when we believe is
            appropriate to comply with the law, enforce or apply our conditions
            of use, and other agreements, protect the rights, property or safety
            of Us, our users or others. This includes exchanging information
            with other companies and organizations for fraud protection and
            credit risk reduction.
          </li>
        </ul>

        <li className="list1">COOKIES</li>
        <ul className="list1-ul">
          <li className="list2">
            To optimize our web presence, we use cookies. These are small text
            files stored on your computer's main memory. These cookies are
            deleted after you close the browser. Other cookies remain on your
            computer (long-term cookies) and permit its recognition on your next
            visit. This allows us to improve your access to our site. This helps
            us to learn more about your interests, and provide you with
            essential features and services and for additional purposes,
            including:
          </li>
          <ul className="list2-ul">
            <li className="list3">
              Keeping track of items stored in your shopping basket.
            </li>
            <li className="list3">
              Conducting research and diagnostics to improve the content,
              products, and services.
            </li>
            <li className="list3">Preventing fraudulent activity.</li>
            <li className="list3">Improving security.</li>
          </ul>
          <li className="list2">
            Our cookies allow you to take advantage of some of our essential
            features. For instance, if you block or otherwise reject our
            cookies, you will not be able to add items to your shopping basket,
            proceed to checkout, or use any products or services that require
            you to sign in.
          </li>
          <li className="list2">
            Approved third parties also may set cookies when you interact with
            Our services.
          </li>
          <li className="list2">
            Third parties include search engines, providers of measurement and
            analytics services, social media networks, and advertising
            companies. e. Third parties use cookies in the process of delivering
            content, including ads relevant to your interests, to measure the
            effectiveness of their ads, and to perform services on behalf of Us.
          </li>
          <li className="list2">
            You can prevent the storage of cookies by choosing a "disable
            cookies" option in your browser settings. But this can limit the
            functionality of our services.
          </li>
        </ul>

        <li className="list1"> DATA SECURITY </li>
        <ul className="list1-ul">
          <li className="list2">
            We take due care to protect customer data. Technical measures are in
            place to prevent unauthorized or unlawful access to data and against
            accidental loss or destruction of, or damage to, data. The employees
            who are dealing with the data have been trained to protect the data
            from any illegal or unauthorized usage.
          </li>
          <li className="list2">
            {" "}
            We work to protect the security of your information during
            transmission by using Secure Sockets Locker (SSL) software, which
            encrypts information you input. SSL allows sensitive information
            such as credit card numbers, UID's and login credentials to be
            transmitted securely.
          </li>
          <li className="list2">
            {" "}
            We follow the Payment Card Industry Data Security Standard (PCI DSS)
            when handling branded credit cards from the major card schemes.
          </li>
          <li className="list2">
            We maintain physical, electronic, and procedural safeguards in
            connection with the collection, storage, and disclosure of personal
            customer information.
          </li>
          <li className="list2">
            {" "}
            We take reasonable steps to help protect your personal information
            in an effort to prevent the loss, misuse, and unauthorized access,
            disclosure alteration and destruction. It is your responsibility to
            protect your user names and passwords to help prevent anyone from
            accessing or abusing your accounts and services. You should not use
            or reuse the same passwords you use with other accounts as your
            password for our services.
          </li>
          <li className="list2">
            It is important for you to protect against unauthorized access to
            your password and your computers, devices, and applications. Be sure
            to sign off when you finish using a shared computer.
          </li>
          <li className="list2">
            Information you provide to us is shared on our secure servers. We
            have implemented appropriate physical, technical and organizational
            measures designed to secure your information against accidental loss
            and unauthorized access, use, alteration or disclosure. In addition,
            we limit access to personal data to those employees, agents,
            contractors, and other third parties that have a legitimate business
            need for such access.
          </li>
          <li className="list2">
            {" "}
            Information collected from you will be stored for such period as
            required to complete the transaction entered into with you or such
            period as mandated under the applicable laws.
          </li>
        </ul>
      </ul>
    </div>
  );
};

export const TermsandCondition = () => {
  return (
    <div
      style={{
        border: "1px solid #f1f1f1",
        backgroundColor: "#fff",
        textAlign: "left",
      }}
    >
      <h4>
        For purchases via our website, you agree to the terms of this agreement
        which will bind you.{" "}
      </h4>
      <ul>
        <li className="list1"> Definitions </li>
        <ul className="list1-ul">
          <li className="list2">
            {" "}
            “Confidential Information” means information provided by one party
            to the other in written, graphic, recorded, machine readable or
            other form concerning the business, clients, suppliers, finances and
            other areas of the other party’s business or products, including,
            without limitation, the Course Materials, but does not include
            information in the public domain other than through the default of
            the party disclosing the information, information required to be
            disclosed by any court or regulatory authority, or any information
            already in the possession or control of the disclosing party.
          </li>

          <li className="list2">
            {" "}
            “Course Materials” means the information provided by Us to accompany
            a course provided as part of the Services in hard copy or electronic
            form.
          </li>

          <li className="list2">
            {" "}
            “Fees” means the fees paid by you to us for the Services.
          </li>

          <li className="list2">
            {" "}
            “Intellectual Property Rights” means copyright, rights in or
            relating to databases, patent rights, performers’ rights, designs
            and registered designs, trademarks, rights in or relating to
            Confidential Information and other intellectual property rights
            (registered or unregistered) throughout the world.
          </li>

          <li className="list2">
            {" "}
            “Online Course” means the delivery by us of an online course
            pursuant to which you learn course materials remotely.
          </li>

          <li className="list2">
            {" "}
            “Services” means the provision of the Online Course and/or the
            Taught Course and/or the Course Materials together with such other
            services as agreed from time to time and purchased by you through
            the Website or by telephone.
          </li>

          <li className="list2">
            {" "}
            “Taught Course” means a course taught by us in a classroom setting
            to which you attend in person.
          </li>

          <li className="list2">
            {" "}
            “you” means the individual purchasing the Services.
          </li>
        </ul>

        <li className="list1"> The Services</li>
        <ul className="list1-ul">
          <li className="list2">
            {" "}
            A description of the Services together with the dates on which the
            Services will begin are available on our Website. We will provide
            the Services with reasonable care and skill in accordance with the
            description set out on the Website.
          </li>

          <li className="list2">
            {" "}
            We reserve the right to vary or withdraw any of the Services
            described on the Website without notice.
          </li>

          <li className="list2">
            {" "}
            We expect you to confirm that the Services you are purchasing will
            meet your needs. We do not make any guarantee to you that you will
            obtain a particular result, professional qualification or employment
            opportunity from your purchase and completion of any of the
            Services.
          </li>
        </ul>

        <li className="list1">Ordering Services</li>
        <ul className="list1-ul">
          <h5>Purchasing Services via the Website</h5>
          <li className="list2">
            {" "}
            In order to purchase any of the Services on-line you must register
            for an account with us via the Website. If you already have an
            account with us you can log into your account using your user name
            and password.
          </li>
        </ul>

        <li className="list1"> Cancellation and Variation</li>
        <ul className="list1-ul">
          <li className="list2">
            {" "}
            If you have purchased an Online Course and have already accessed,
            downloaded all or part of the Online Course and/or started to use
            that Online Course then you shall have no right to cancel your
            order.
          </li>
          <li className="list2">
            {" "}
            Notwithstanding clause 4.1 there is no other right to cancel or vary
            your purchase of Services and any other cancellation and / or
            variation of course dates will be at the entire discretion.
          </li>
        </ul>

        <li className="list1"> Fees</li>
        <ul className="list1-ul">
          <li className="list2">
            {" "}
            The Fees for the Services shall be as set out on the Website or as
            told to you over the telephone at the time you placed an order for
            them.
          </li>
          <li className="list2">
            {" "}
            Unless otherwise specified at the time you purchase the Services the
            Fees are exclusive of VAT or other local taxes, the cost of some
            Course Materials and any delivery costs payable in respect of the
            delivery of Course Material to you. Each of these costs will be set
            out in the Website or told to you over the telephone prior to your
            purchase the Services.
          </li>

          <li className="list2">
            {" "}
            Fees for the Service selected by you on the Website or purchased
            over the telephone shall be debited from your credit / debit card at
            the time of purchase. Fees must be paid in full prior to you
            attending any Taught Course or accessing any Online Course.
          </li>
          <li className="list2">
            {" "}
            Any fees charged by your debit or credit card provider in connection
            with your purchase of Services are for your own account and we shall
            not be responsible for these.
          </li>
          <li className="list2">
            {" "}
            You shall be responsible for all costs you incur in connection with
            your attendance at any Taught Courses or your access onto any Online
            Course.
          </li>
        </ul>

        <li className="list1"> Liability</li>
        <ul className="list1-ul">
          <li className="list2">
            {" "}
            No part of the provision of the Services shall be deemed to be, nor
            is it intended to be, nor should it be taken to be, the provision of
            investment advice.
          </li>
          <li className="list2">
            {" "}
            Although we aims to provide the Services to the highest standards of
            the industry, neither it, nor its trainers accept any liability for
            (i) any inaccuracy or misleading information provided in the
            programmes or Course Materials and any reliance by Client on any
            such information, (ii) any loss or corruption of data, (iii) any
            loss of profit, revenue or goodwill, or (iv) any indirect, special
            or consequential loss arising from any breach of the terms of this
            Agreement.
          </li>
          <li className="list2">
            {" "}
            Except to the extent that they are expressly set out in these terms
            and conditions, no conditions, warranties or other terms shall apply
            to the Services. Subject to clause 6.5 no implied conditions,
            warranties or other terms apply (including any implied terms as to
            satisfactory quality, fitness for purpose or conformance with
            description).
          </li>
          <li className="list2">
            {" "}
            Subject to clause 6.5 below, We's total liability arising from or in
            connection with these terms and conditions and in relation to
            anything which we may have done or not done in connection with these
            terms and conditions and the delivery of the Service (and whether
            the liability arises because of breach of contract, negligence or
            for any other reason) shall be limited to the Fees received by us in
            connection with the relevant Online Course or Taught Course in
            relation to which a dispute has arisen.
          </li>
          <li className="list2">
            {" "}
            Nothing in this Agreement shall exclude or limit our liability for
            (i) death or personal injury caused by negligence, (ii) fraudulent
            misrepresentation or (iii) any other matter which under English law
            may not be limited or excluded.
          </li>
          <li className="list2">
            {" "}
            No claim may be brought more than six months after the last date on
            which the Services concerned have finished or ceased to be provided
            by us.
          </li>
        </ul>

        <li className="list1"> Intellectual Property</li>
        <ul className="list1-ul">
          <li className="list2">
            {" "}
            All Intellectual Property Rights in the Course Materials, Online
            Courses and the speeches made by trainers at the Taught Courses are,
            and remain, the intellectual property of us or its licensors,
            whether adapted, written for or customised for the Client or not.
          </li>
          <li className="list2"> You are not authorised to:-</li>
          <ul className="list2-ul">
            <li className="list3">
              {" "}
              copy, modify, reproduce, re-publish, sub-licence, sell, upload,
              broadcast, post, transmit or distribute any of the Course
              Materials without prior written permission;
            </li>
            <li className="list3">
              {" "}
              record on video or audio tape, relay by videophone or other means
              the Online Course or Taught Course given
            </li>
            <li className="list3">
              {" "}
              use the Course Materials in the provision of any other course or
              training whether given by us or any third party trainer;
            </li>
            <li className="list3">
              {" "}
              remove any copyright or other notice of our company on the Course
              Materials;
            </li>
            <li className="list3">
              {" "}
              modify, adapt, merge, translate, disassemble, decompile, reverse
              engineer (save to the extent permitted by law) any software
              forming part of the Online Courses.
            </li>
          </ul>
          <h5>
            Breach by you of this clause 7.2 shall allow us to immediately
            terminate these terms and conditions with you and cease to provide
            you with any Services, including but not limited to access to the
            Online Courses.
          </h5>
          <li className="list2">
            {" "}
            In consideration of the Fees paid by you, we grant to you a limited,
            non-transferable, non-exclusive licence to use the Course Materials
            and the software in respect of the Online Course for the sole
            purpose of completing the Online Course and / or attending the
            Taught Course.
          </li>
        </ul>

        <li className="list1"> Confidentiality</li>
        <ul className="list1-ul">
          <li className="list2">
            {" "}
            Each party shall keep the other party’s Confidential Information
            strictly confidential and not use it otherwise than for the purposes
            of these terms and conditions, and shall return it on demand and not
            retain copies of it.
          </li>
          <li className="list2">
            {" "}
            Either party may disclose Confidential Information to its legal and
            other advisors for the purposes of obtaining advice from them.
          </li>
          <li className="list2">
            {" "}
            This clause shall continue notwithstanding termination of these
            terms and conditions.
          </li>
        </ul>

        <li className="list1"> Termination</li>
        <ul className="list1-ul">
          <li className="list2">
            {" "}
            We shall be entitled to terminate these terms and conditions and
            cease to provide you with any Services with immediate effect in the
            event that you: fail to pay when due your Fees; act in an
            aggressive, bullying, offensive, threatening or harassing manner
            towards any employee of our company, any teacher or lecturer who
            provides the Taught Courses or any student who attends any Taught
            Course; cheat or plagiarise any work which you are required to
            prepare or submit in connection with the Services or during any
            examination taken in connection with the Services; steal or act in
            fraudulent or deceitful manner towards us or our employees or any
            other students who may be on our premises or attending our Taught
            Courses; intentionally or recklessly damage our property or the
            property of our employees or other students attending our premises;
            are intoxicated through alcohol or illegal drugs while on our
            premises; commit any criminal offence committed on our premises or
            where the victim is our employee or student; are in breach of these
            terms and conditions.
          </li>
          <li className="list2">
            {" "}
            On termination clause 6 (liability), 7 (intellectual property
            rights), 8 (confidentiality) and 10 (restrictions) shall continue
            notwithstanding such termination.
          </li>
        </ul>

        <li className="list1">Assignment</li>
        <ul className="list1-ul">
          <li className="list2">
            {" "}
            Any Services provided by us under these terms and conditions are
            personal to you and cannot be transferred or assigned to any other
            person.
          </li>
        </ul>

        <li className="list1"> Entire Agreement</li>
        <ul className="list1-ul">
          <li className="list2">
            These terms and conditions, together with the Website Disclaimer and
            Course Specific Terms and Conditions are the entire agreement
            between the parties and supersede any prior agreements and
            arrangements, whether written or oral. You confirm that you have not
            relied on any representations in entering into these and any other
            terms and conditions with us. Nothing in this clause or terms and
            conditions shall limit liability for any fraudulent
            misrepresentation.
          </li>
        </ul>

        <li className="list1"> Force Majeure</li>
        <ul className="list1-ul">
          <li className="list2">
            We shall not be liable to you for any breach of its obligations or
            termination under these terms and conditions arising from causes
            beyond its reasonable control, including, but not limited to, fires,
            floods, earthquakes, volcanoes and other Acts of God, terrorism,
            strikes, delay caused by transport disputes, failure to provide a
            course caused by a death in the trainer’s family, illness of the
            trainer, Government edict or regulation.
          </li>
        </ul>

        <li className="list1"> Assignment</li>
        <ul className="list1-ul">
          <li className="list2">
            We may assign, transfer, sub-contract any of our rights or
            obligations to any third party at our discretion.
          </li>
        </ul>
      </ul>
    </div>
  );
};
