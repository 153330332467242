import React, { Component } from "react";
import LogoImage from "../../assets/logo2.png";
import CartImage from "../../assets/scart.png";
import { Link, withRouter } from "react-router-dom";
import { social_links, returnLinks } from "../../routes/home";
import { connect } from "react-redux";
import HELPERS from "../../utils/helper";
import apis from "../../config/apis";
import { env } from "../../config";
import Alert from "../../components/alert";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      top_strips: [
        { name: "ABOUT", link: "/about" },
        { name: "COURSES", link: "/courses" },
        { name: "PRODUCTS", link: "/product" },
      ],
      header_menus: [
        { name: "HOME", link: "/" },
        { name: "FAQs", link: "/faqs" },
        { name: "MORE", link: "" },
        { name: "ABOUT", link: "/about" },
        { name: "CONTACT US", link: "/contact" },
      ],
    };
  }

  setDetails = (data) => {
    if (data && data.basic_details) {
      const favicon = document.getElementById("favicon");
      favicon.href = data.basic_details.favicon_url;

      if (
        data.theme_details.text_heading_color &&
        data.theme_details.text_heading_color !== ""
      ) {
        document.documentElement.style.setProperty(
          "--theme-color",
          data.theme_details.text_heading_color
        );
      }

      if (
        data.theme_details.text_content_color &&
        data.theme_details.text_content_color !== ""
      ) {
        document.documentElement.style.setProperty(
          "--text-color",
          data.theme_details.text_content_color
        );
      }

      let pixel = data.third_party_integration_details.pixel;
      let google_verfication =
        data.third_party_integration_details.google_verfication;
      let google_analytics =
        data.third_party_integration_details.google_analytics;

      try {
        if (pixel !== null && pixel !== "") {
          eval(pixel);
        }
      } catch (err) {
        console.log("pixel", err);
      }

      try {
        if (google_verfication !== null && google_verfication !== "") {
          eval(google_verfication);
        }
      } catch (err) {
        console.log("google verification", err);
      }

      try {
        if (google_analytics !== null && google_analytics !== "") {
          eval(google_analytics);
        }
      } catch (err) {
        console.log("other script", err);
      }

      try {
        if (
          data.third_party_integration_details &&
          data.third_party_integration_details["chat"] !== undefined &&
          data.third_party_integration_details["chat"] !== null
        ) {
          const script = document.createElement("script");
          script.src = data.third_party_integration_details.chat;
          document.body.appendChild(script);
        }
      } catch (err) {
        console.log("chat bot err", err);
      }
    }
  };

  componentDidMount = () => {
    if (this.props.website_enable) {
      this.setDetails(this.props.data);
    } else if (this.props.site_data && this.props.site_data.fav_icon_url) {
      const favicon = document.getElementById("favicon");
      favicon.href = this.props.site_data.fav_icon_url;
    }
    if (document.title == "Institute Website") {
      document.title = this.props.institute_name;
    }

    if (this.props.site_data && this.props.site_data.enable_elearn_feature) {
      this.setState({
        header_menus: [
          { name: "HOME", link: "/" },
          { name: "COURSES", link: "/courses" },
          { name: "PRODUCTS", link: "/product" },
          { name: "FAQs", link: "/faqs" },
          { name: "MORE", link: "" },
          { name: "ABOUT", link: "/about" },
          { name: "CONTACT US", link: "/contact" },
        ],
      });
    } else if (
      this.props.site_data &&
      !this.props.site_data.school_model &&
      !this.props.site_data.enable_elearn_feature
    ) {
      this.setState({
        header_menus: [
          { name: "HOME", link: "/" },
          { name: "COURSES", link: "/courses" },
          { name: "FAQs", link: "/faqs" },
          { name: "MORE", link: "" },
          { name: "ABOUT", link: "/about" },
          { name: "CONTACT US", link: "/contact" },
        ],
      });
    }
  };

  onClickDashboard = () => {
    const { user_info } = this.props;
    window.open(
      `${window.location.origin}/my-account/?user=${
        user_info.user_name
      }&pass=${btoa(user_info.password)}`,
      "_self"
    );
  };

  onLogout = async () => {
    const { device_id, student_auth } = this.props;
    let did = device_id,
      st = student_auth;
    this.props.dispatch({ type: "LOGOUT", message: "" });
    let headers = {
      headers: {
        Authorization: st,
        "Content-Type": "application/json",
      },
    };
    try {
      let res = await HELPERS.ApiCall(
        apis.LOGOUT + did,
        {},
        "GET",
        "proctur",
        headers
      );
    } catch (error) {}
  };

  onUserLogout = () => {
    this.props.dispatch({
      type: "UPDATE_WEB_ACCESS_POP_UP",
      show_web_access_pop_up: false,
    });
    window.location = window.origin;
  };

  render() {
    const { top_strips, header_menus } = this.state;
    const {
      data,
      cart,
      user_id,
      website_enable,
      site_data,
      access_token,
      show_web_access_pop_up,
      web_access_msg,
    } = this.props;
    let theme_color = getComputedStyle(
      document.documentElement
    ).getPropertyValue("--theme-color");

    return (
      <>
        {((data && data.basic_details && website_enable) ||
          !website_enable) && (
          <div className="header">
            <div className="headerMenu">
              <div className="headerContent">
                <div className="logoDiv">
                  <img
                    onClick={() => {
                      this.props.history.push("");
                    }}
                    className="logoImage"
                    src={
                      website_enable
                        ? data.basic_details.logo_url
                        : site_data !== undefined &&
                          site_data.institute_logo_url !== undefined &&
                          site_data.institute_logo_url !== null &&
                          site_data.institute_logo_url !== ""
                        ? site_data.institute_logo_url
                        : ""
                    }
                    alt="Logo here"
                  />
                </div>

                {website_enable && (
                  <div className="menuDiv">
                    {header_menus.map((item, index) => {
                      if (item.name !== "MORE") {
                        return (
                          <Link key={index} to={item.link}>
                            {item.name}
                          </Link>
                        );
                      } else {
                        return (
                          data.nav_links.length > 0 && (
                            <div className="dropdown">
                              <button>
                                MORE <i className="fa fa-caret-down"></i>
                              </button>
                              <div className="more-content">
                                {data.nav_links.map((item, i) => {
                                  if (item.in_navbar) {
                                    return (
                                      <Link
                                        key={i}
                                        to={{
                                          pathname: `/more${
                                            item.page_route.indexOf("/") > -1
                                              ? item.page_route
                                              : `/${item.page_route}`
                                          }`,
                                          state: {
                                            label: item.page_title,
                                            slug: item.page_route,
                                            hide_image: false,
                                          },
                                        }}
                                      >
                                        {item.page_title}
                                      </Link>
                                    );
                                  }
                                })}
                              </div>
                            </div>
                          )
                        );
                      }
                    })}
                  </div>
                )}

                {!website_enable &&
                  site_data &&
                  site_data.enable_elearn_feature &&
                  !site_data.school_model && (
                    <div className="menuDiv">
                      <Link key={1} to={"/product"}>
                        PRODUCTS
                      </Link>
                    </div>
                  )}

                <div className="loginCartDiv">
                  {website_enable && (
                    <div className="showMore">
                      {data.nav_links.length > 0 && (
                        <div className="dropdown">
                          <button>
                            MORE <i className="fa fa-caret-down"></i>
                          </button>
                          <div className="more-content">
                            {data.nav_links.map((item, i) => {
                              if (item.in_navbar) {
                                return (
                                  <Link
                                    style={{ padding: 10 }}
                                    key={i}
                                    to={{
                                      pathname: `/more${
                                        item.page_route.indexOf("/") > -1
                                          ? item.page_route
                                          : `/${item.page_route}`
                                      }`,
                                      state: {
                                        label: item.page_title,
                                        slug: item.page_route,
                                        hide_image: false,
                                      },
                                    }}
                                  >
                                    {item.page_title}
                                  </Link>
                                );
                              }
                            })}
                          </div>
                        </div>
                      )}
                    </div>
                  )}

                  {website_enable && (
                    <div className="dropdown">
                      <div className="menuBar">
                        {" "}
                        <i className="fa fa-bars"></i>{" "}
                      </div>
                      <div className="more-content" style={{ right: 0 }}>
                        {header_menus.map((item, index) => {
                          if (item.name !== "MORE") {
                            return (
                              <Link
                                style={{ padding: 10 }}
                                key={index}
                                to={item.link}
                              >
                                {item.name}
                              </Link>
                            );
                          }
                        })}
                        {this.props.user_id == 0 &&
                          access_token.web_access == true && (
                            <Link
                              key={"login"}
                              style={{ padding: 10 }}
                              to={{
                                pathname: "/login",
                                state: { from_cart: false },
                              }}
                            >
                              STUDENT LOGIN
                            </Link>
                          )}

                        {this.props.user_id !== 0 &&
                          access_token.web_access == true && (
                            <a
                              key={"dashboard"}
                              style={{ padding: 10 }}
                              onClick={this.onClickDashboard}
                            >
                              DASHBOARD
                            </a>
                          )}

                        {this.props.user_id !== 0 && (
                          <a
                            key={"logout"}
                            style={{ padding: 10 }}
                            href={"#"}
                            onClick={this.onLogout}
                          >
                            LOGOUT
                          </a>
                        )}
                      </div>
                    </div>
                  )}

                  {!website_enable && (
                    <div className="menuBar login">
                      {this.props.user_id == 0 &&
                        access_token.web_access == true && (
                          <Link
                            style={{ color: "#fff", fontSize: 12 }}
                            to={{
                              pathname: "/login",
                              state: { from_cart: false },
                            }}
                          >
                            <i className="fa fa-user-circle"></i>&nbsp;&nbsp;{" "}
                            {"STUDENT LOGIN"}
                          </Link>
                        )}
                      {this.props.user_id !== 0 && (
                        <div className="dropdown">
                          <button
                            style={{
                              backgroundColor: theme_color,
                              color: "white",
                              padding: 0,
                              borderRadius: 6,
                              fontSize: 12,
                              marginRight: 10,
                            }}
                          >
                            MY ACCOUNT <i className="fa fa-caret-down"></i>
                          </button>
                          <div
                            className="more-content"
                            style={{ marginTop: 4 }}
                          >
                            {access_token.web_access == true && (
                              <a
                                style={{
                                  padding: 10,
                                  color: "black",
                                  fontSize: 12,
                                  background: "none",
                                }}
                                onClick={this.onClickDashboard}
                              >
                                DASHBOARD
                              </a>
                            )}
                            <a
                              style={{
                                padding: 10,
                                color: "black",
                                fontSize: 12,
                                background: "none",
                              }}
                              href={"#"}
                              onClick={this.onLogout}
                            >
                              LOGOUT
                            </a>
                          </div>
                        </div>
                      )}
                    </div>
                  )}

                  {this.props.user_id == 0 &&
                    access_token.web_access == true && (
                      <div className="loginOption">
                        <Link
                          to={{
                            pathname: "/login",
                            state: { from_cart: false },
                          }}
                        >
                          <i className="fa fa-user-circle"></i>&nbsp;&nbsp;{" "}
                          {"STUDENT LOGIN"}
                        </Link>
                      </div>
                    )}

                  {this.props.user_id !== 0 && (
                    <div className="loginOption" style={{ background: "none" }}>
                      <div className="dropdown">
                        <button
                          style={{
                            backgroundColor: theme_color,
                            color: "white",
                            padding: "6px 10px",
                            borderRadius: 6,
                            fontSize: 12,
                            marginRight: 10,
                          }}
                        >
                          MY ACCOUNT <i className="fa fa-caret-down"></i>
                        </button>
                        <div className="more-content">
                          {access_token.web_access == true && (
                            <a
                              style={{
                                padding: 10,
                                color: "black",
                                fontSize: 12,
                                background: "none",
                              }}
                              onClick={this.onClickDashboard}
                            >
                              DASHBOARD
                            </a>
                          )}
                          <a
                            style={{
                              padding: 10,
                              color: "black",
                              fontSize: 12,
                              background: "none",
                            }}
                            href={"#"}
                            onClick={this.onLogout}
                          >
                            LOGOUT
                          </a>
                        </div>
                      </div>
                    </div>
                  )}

                  <Link to="/cart">
                    {cart && cart.length > 0 && (
                      <span className="cartno">{cart.length}</span>
                    )}
                    <img src={CartImage} className="cartimg" />
                  </Link>

                  {site_data &&
                    (!site_data.enable_elearn_feature ||
                      site_data.school_model) && <div style={{ height: 30 }} />}
                </div>
              </div>
            </div>
          </div>
        )}
        <Alert
          show={show_web_access_pop_up ? true : false}
          heading={""}
          content={
            web_access_msg && web_access_msg.length > 0 ? web_access_msg[0] : ""
          }
          handleClose={this.onUserLogout}
          handleOk={this.onUserLogout}
          hideClose={true}
          link={
            web_access_msg && web_access_msg.length > 1 ? web_access_msg[1] : ""
          }
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return { ...state.home, ...state.cart, ...state.auth };
};
export default connect(mapStateToProps)(withRouter(Header));
