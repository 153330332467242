export const CLIENTS = {
  a5_testbed: {
    institute_id: 100058,
    institute_name: "Proctur",
  },
  a5_prod: {
    institute_id: 100127,
    institute_name: "Proctur",
  },
  pro_elearn_testbed: {
    institute_id: 100144,
    institute_name: "Pro Elearn",
  },
};
