import React, { Component } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { connect } from "react-redux";
import { result } from "../home/data";

class AboutUs extends Component {
  componentDidMount = () => {
    window.scrollTo(0, 0);
  };

  render() {
    const { data } = this.props;
    return (
      <>
        <Header />
        <div className="content bgimg" style={{ paddingTop: 70 }}>
          <div className="about-us">
            <h2 className="about-us-heading">ABOUT US</h2>

            {data &&
              data.about_us_details &&
              data.about_us_details.banner_image_url && (
                <img
                  style={{ marginTop: 50 }}
                  src={data.about_us_details.banner_image_url}
                  alt="Banner Image"
                />
              )}

            <div
              className="about-us-data"
              style={{
                width:
                  data &&
                  data.about_us_details &&
                  data.about_us_details.banner_image_url
                    ? "68%"
                    : "100%",
                paddingLeft: 30,
              }}
            >
              <h2 style={{ marginBottom: 30 }}>ABOUT US</h2>
              {data &&
                data.about_us_details &&
                data.about_us_details.page_data && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data.about_us_details.page_data,
                    }}
                  ></div>
                )}

              {(!data ||
                !data.about_us_details ||
                !data.about_us_details.page_data) && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: result.about_us_details.page_data,
                  }}
                ></div>
              )}
            </div>
          </div>
        </div>

        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return { ...state.home };
};
export default connect(mapStateToProps)(AboutUs);
