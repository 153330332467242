import React, { Component } from "react";
import { store } from "../redux/store";
import CONFIG from "../config";
import axios from "axios";
import { ErrorMessageHandling } from "../components/error";

export const playVideo = async (item, play, message, loader) => {
  let student_auth = store.getState().auth.student_auth;
  let URL =
    CONFIG.BASE_URL +
    `/api/v1/meeting_manager/recording/download/${CONFIG.INSTITURE_ID}/${item.download_id}?type=1`;
  const options = {
    headers: {
      Authorization: student_auth,
      "Content-Type": "application/json",
    },
  };

  await axios
    .get(URL, options)
    .then((res) => {
      loader(false);
      if (res.status == 200 && res.data.video_url) {
        play(res.data.video_url, item);
      } else {
        message(
          "e",
          "Unable to play video, Kindly contact your adminstration!"
        );
      }
    })
    .catch((err) => {
      loader(false);
      if (err && err.response && err.response.message) {
        message("e", err.response.message);
      } else {
        message(ErrorMessageHandling(err));
      }
    });
};

export const downloadVideoFile = async (item) => {
  // let URL = CONFIG.BASE_URL + `/api/v1/meeting_manager/recording/download/${CONFIG.INSTITURE_ID}/${item.download_id}`;
  let URL =
    "https://api.proctur.com/StdMgmtWebAPI/api/v1/meeting_manager/recording/download/100127/7144";
  let options = {
    headers: {
      Authorization: "MzE5Mzh8MTo0ODgzOjEwMDEyNw==",
      "Content-Type": "application/json",
    },
  };

  await axios
    .get(URL, options)
    .then((res) => {
      const element = document.createElement("a");
      const file = new Blob(res, { type: "video/mp4" });
      element.href = URL.createObjectURL(file);
      element.download = "myFile.mp4";
      document.body.appendChild(element);
      element.click();
    })
    .catch((err) => {});
};
