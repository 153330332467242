import axios from "axios";
import CONFIG from "../config";

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const header = { "Content-Type": "application/json" };
const instance = (type) => {
  switch (type) {
    case "proctur":
      return axios.create({
        baseURL: CONFIG.BASE_URL,
        timeout: 20000,
      });
    case "product":
      return axios.create({
        baseURL: CONFIG.PRODUCT_BASE_URL,
        timeout: 20000,
      });
    default:
      return axios.create({
        baseURL: CONFIG.BASE_URL,
        timeout: 3000,
        headers: header,
      });
  }
};

const HELPERS = {
  ApiCall: (url, payload, apiType, headerType, headers) => {
    let fheaders = { headers: { ...headers.headers, Source: "WEB" } };
    if (apiType === "PUT") {
      return instance(headerType).put(url, payload, fheaders);
    } else if (apiType === "POST") {
      return instance(headerType).post(url, payload, fheaders);
    } else {
      return instance(headerType).get(url, fheaders);
    }
  },

  validateString: function (val) {
    return val === "" ? false : true;
  },

  validateNumber: function (val, start, end) {
    return !isNaN(val) && val >= start && val <= end ? true : false;
  },
  validateName: function (name) {
    var reg = /^[A-Za-z\s]+$/;
    return name === "" || reg.test(name) ? true : false;
  },
  validateMobileNumber: function (number) {
    var reg = /^[0-9\b]+$/;
    return number === "" || reg.test(number) ? true : false;
  },
  validateEmail: function (email) {
    var reg =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return reg.test(email) ? true : false;
  },
  validatePass: function (pass) {
    return pass.length < 6 || pass.length > 10 ? false : true;
  },
  formatDate: function (date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  },
  getCurrentMonthName: function () {
    var d = new Date();
    var i = d.getMonth();
    return months[i];
  },
};

export default HELPERS;
