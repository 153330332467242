import React, { Component } from "react";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";

class OnlineExams extends Component {
  render() {
    return (
      <>
        <h4>Online Exams</h4>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return { ...state.auth };
};
export default connect(mapStateToProps)(withRouter(OnlineExams));
