import CONFIG from ".";

const apis = {
  GET_INSTITUTE_ID: "/api/v1/product-ePortal/get?webUrl=",

  WEB_ACCESS_TOKEN: `/api/v1/institutes/webAccessToken/`,
  LOGIN: "/api/v2/user/v3/login",
  FORGOT_PASSWORD: "/api/v2/user/forgotPassword",
  REGISTER: "/api/v2/user/register",
  GENERATE_OTP: "/api/v2/user/login/generate_otp",
  VALIDATE_OTP: "/api/v2/user/login/validate_otp",
  GET_SUB_BRANCHES: "/api/v1/institutes/all/subBranches/",

  GET_COURSES: `/api/v1/institute/courseMapping/get/`,
  GET_COUNTRY_CODES: `/api/v1/institutes/country/`,

  HOMEPAGE: `/prod/api/v2/website/home/institute/`,
  FAQS: `/prod/api/v2/website/faq/institute/`,
  GET_CLIENT_PAGE_DATA: `/prod/api/v2/website/page/institute/`,

  PRODUCT_LIST: "/prod/student-product/advance-filter/?getAll=true",
  PRODUCT_DESC: "/prod/student-product/item-type-description/",
  PRODUCT_INFO: "/prod/product/get-product-details",
  CART_LIST: "/prod/product/get-cart-products",
  APPLY_COUPON: "/prod/use-offer/apply",
  CREATE_ORDER: "/prod/order/create",
  UPDATE_ORDER: "/prod/order/update",

  MY_ORDER: "/prod/student-product/my-products",

  PROFILE: "/api/v1/alternateLogin/user/fetch/",
  CHANGE_PASSWORD: "/api/v1/changePwd",

  GET_PAYMENT_METHOD: "/api/v1/payment-gateway/",
  SEND_MESSAGE: "/api/v1/extLeads/website",

  LIVE_CLASS: `/api/v1/meeting_manager/getMeeting/`,

  LOGOUT: "/api/v1/alternateLogin/logout?deviceId=",
};

export default apis;
