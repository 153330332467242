import React, { Component } from "react";
export default class FAQs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      faqs: [],
      loader: true,
      faqs_index: [],
    };
  }
  componentDidMount = () => {
    this.setState({
      loader: false,
      faqs: this.props.faqs,
      faqs_index: Array(this.props.faqs.length).fill(false),
    });
  };

  onClickFaqs = (i) => {
    const { faqs_index } = this.state;
    let arr = faqs_index;
    arr[i] = !faqs_index[i];
    this.setState({ faqs_index: arr });
  };

  render() {
    const { faqs, loader, faqs_index } = this.state;
    return (
      <>
        {!loader && (
          <div className="faqs">
            {faqs.map((item, index) => (
              <div key={index} className="faqContent">
                <h4
                  style={{
                    color: faqs_index[index] ? "#b91a3e" : "",
                    borderBottom: faqs_index[index]
                      ? "1px solid #b91a3e"
                      : "1px solid #244b81",
                  }}
                >
                  {item.question}
                  <span>
                    <i
                      onClick={() => {
                        this.onClickFaqs(index);
                      }}
                      className={
                        faqs_index[index] ? "fa fa-minus" : "fa fa-plus"
                      }
                    ></i>
                  </span>
                </h4>
                {faqs_index[index] && <p>{item.answer}</p>}
              </div>
            ))}
          </div>
        )}
      </>
    );
  }
}
