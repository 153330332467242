import React from 'react'

export const LongDescription = ({ show, item, onClose }) => {
    const showHideClassName = show ? "alert display-block" : "alert display-none";

    return (
        <>
            <div className={showHideClassName}>
                <section className="courseDesc" style={{ maxHeight: window.innerHeight - 200 }} >
                    <h4>{item.course_type ? item.course_type : item.name}</h4>
                    <p style={{ paddingTop: 10 }} dangerouslySetInnerHTML={{ __html: !item.long_description || item.long_description == "" ? "Description not available!" : item.long_description }} ></p>
                    <div className="buttonContainer" >
                        <button onClick={onClose} className="ok">Ok</button>
                    </div>
                </section>
            </div>
        </>
    )
}