import React, { Component } from "react";
import Playstore from "../../assets/playstore.png";
import Appstore from "../../assets/appstore.png";
import { social_links, returnLinks } from "../../routes/home";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { result } from "../../routes/home/data";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bottom_important_links: [
        { name: "ABOUT US", link: "/about" },
        { name: "CONTACT US", link: "/contact" },
      ],
      bottom_other_links: [
        { name: "PRIVACY POLICY", to: "/privacy-policy" },
        { name: "REFUND POLICY", to: "/refund-policy" },
        { name: "TERMS & CONDITIONS", to: "/terms-and-condition" },
      ],
    };
  }

  componentDidMount = () => {
    if (this.props.site_data && this.props.site_data.enable_elearn_feature) {
      this.setState({
        bottom_important_links: [
          { name: "ABOUT US", link: "/about" },
          { name: "CONTACT US", link: "/contact" },
          { name: "COURSES", link: "/courses" },
          { name: "PRODUCTS", link: "/product" },
        ],
      });
    } else if (
      this.props.site_data &&
      !this.props.site_data.school_model &&
      !this.props.site_data.enable_elearn_feature
    ) {
      this.setState({
        bottom_important_links: [
          { name: "ABOUT US", link: "/about" },
          { name: "CONTACT US", link: "/contact" },
          { name: "COURSES", link: "/courses" },
        ],
      });
    }
  };

  render() {
    const { bottom_important_links, bottom_other_links } = this.state;
    const { data, website_enable } = this.props;
    if (website_enable) {
      return (
        <>
          {data && data.basic_details && (
            <div className="footer">
              <div className="footerContent">
                <div className="footerSection2">
                  <h2>CONTACT DETAILS</h2>

                  {/* {data && data.about_us_details && data.about_us_details.page_data &&
                                        <div dangerouslySetInnerHTML={{ __html: data.about_us_details.page_data.length > 140 ? (data.about_us_details.page_data.slice(0, 140) + '...') : data.about_us_details.page_data }} ></div>
                                    }

                                    {(!data || !data.about_us_details || !data.about_us_details.page_data) &&
                                        <div dangerouslySetInnerHTML={{ __html: result.about_us_details.page_data.length > 140 ? (result.about_us_details.page_data.slice(0, 140) + '...') : result.about_us_details.page_data }} ></div>
                                    } */}

                  {data.social_details.address && (
                    <p className="contact">
                      <a
                        href={`https://maps.google.com/?q=${data.social_details.address}`}
                        target={"_blank"}
                      >
                        <i className="fa fa-map-marker"></i>
                        {data.social_details.address}
                      </a>
                    </p>
                  )}
                  <p className="contact">
                    {data.social_details.email_id && (
                      <a href={`mailto: ${data.social_details.email_id}`}>
                        <i className="fa fa-envelope-o mr4"></i>
                        {data.social_details.email_id}
                      </a>
                    )}
                    {data.social_details.contact && (
                      <a href={`tel:${data.social_details.contact}`}>
                        <i className="fa fa-phone mr4"></i>
                        {data.social_details.contact}{" "}
                      </a>
                    )}
                  </p>
                </div>

                <div className="footerSection1">
                  <h2>IMPORTANT LINKS</h2>
                  {bottom_important_links.map((item, index) => (
                    <Link key={index} to={item.link}>
                      {item.name}
                    </Link>
                  ))}
                  <p className="social">
                    {social_links.map((item, index) =>
                      returnLinks(item, data.social_details, index)
                    )}
                  </p>
                </div>

                <div className="footerSection1">
                  <h2>OTHER LINKS</h2>

                  {bottom_other_links.map((item, index) => (
                    <Link
                      key={index}
                      to={{
                        pathname: `/more${item.to}`,
                        state: {
                          label: item.page_title,
                          slug: item.to,
                          hide_image: true,
                        },
                      }}
                    >
                      {item.name}
                    </Link>
                  ))}

                  {data.social_details.android_app_url &&
                    data.social_details.android_app_url !== "" && (
                      <a
                        href={data.social_details.android_app_url}
                        target="_blank"
                        className="app"
                      >
                        <img src={Playstore} />
                      </a>
                    )}
                  {data.social_details.ios_app_url &&
                    data.social_details.ios_app_url !== "" && (
                      <a
                        href={data.social_details.ios_app_url}
                        target="_blank"
                        className="app"
                      >
                        <img src={Appstore} />
                      </a>
                    )}
                </div>
              </div>
              {data.basic_details.copyright_url &&
                data.basic_details.copyright_url !== "" && (
                  <div className="footerStrip">
                    <p>&copy; {data.basic_details.copyright_url} </p>
                  </div>
                )}
            </div>
          )}
        </>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return { ...state.home, ...state.auth };
};
export default connect(mapStateToProps)(Footer);
