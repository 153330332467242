
import React, { Component } from 'react'
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";

class Documents extends Component {
    render() {
        return (
            <>
            <h4>Documents</h4>
            </>
        )
    }
}

const mapStateToProps = state => { return { ...state.auth }; };
export default connect(mapStateToProps)(withRouter(Documents));;