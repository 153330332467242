import Home from "./home";
import Login from "./auth/login";
import Register from "./auth/register";
import Forgot from "./auth/forgot";
import Dashboard from "./dashboard";
import Products from "./products";
import ContactUs from "./contact";
import Courses from "./courses";
import FAQs from "./faq";
import MyCart from "./cart";
import AboutUs from "./about";
import ProductDesc from "./products/productDesc";
import ThankYou from "../components/thankyou";
import ClientPage from "./policies";
import Profile from "./profile";
import Fail from "../components/fail";

const dashboardRoutes = [
  {
    path: [
      "/dashboard",
      "/dashboard/my-orders",
      "/dashboard/profile",
      "/dashboard/live-class",
      "/dashboard/online-exam",
      "/dashboard/study",
      "/dashboard/online-assignment",
      "/dashboard/time-table",
      "/dashboard/offline-exams",
      "/dashboard/documents",
    ],
    exact: true,
    strict: true,
    component: Dashboard,
  },
  { path: "/product", exact: true, strict: true, component: Products },
  { path: "/courses", exact: true, strict: true, component: Courses },
  { path: "/cart", exact: true, strict: true, component: MyCart },
  {
    path: "/product?proc=:id",
    exact: true,
    strict: true,
    component: ProductDesc,
  },
  { path: "/", exact: true, strict: true, component: Home },
];
const siteRoutes = [
  { path: "/", exact: true, strict: true, component: Home },
  { path: "/login", exact: true, strict: true, component: Login },
  { path: "/register", exact: true, strict: true, component: Register },
  { path: "/forgot", exact: true, strict: true, component: Forgot },
  { path: "/about", exact: true, strict: true, component: AboutUs },
  { path: "/contact", exact: true, strict: true, component: ContactUs },
  { path: "/faqs", exact: true, strict: true, component: FAQs },
  { path: "/courses", exact: true, strict: true, component: Courses },
  { path: "/product", exact: true, strict: true, component: Products },
  { path: "/cart", exact: true, strict: true, component: MyCart },
  {
    path: "/product?proc=:id",
    exact: true,
    strict: true,
    component: ProductDesc,
  },
  { path: "/thankyou", exact: true, strict: true, component: ThankYou },
  { path: "/more/:id", exact: true, strict: true, component: ClientPage },
  {
    path: "/more/privacy-policy",
    exact: true,
    strict: true,
    component: ClientPage,
  },
  {
    path: "/more/refund-policy",
    exact: true,
    strict: true,
    component: ClientPage,
  },
  {
    path: "/more/terms-and-condition",
    exact: true,
    strict: true,
    component: ClientPage,
  },
  { path: "/fail", exact: true, strict: true, component: Fail },
];

export { siteRoutes, dashboardRoutes };
