import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import apis from "../../config/apis";
import HELPERS from "../../utils/helper";
import Error, { ErrorMessageHandling } from "../../components/error";
import { ToastMessage, Toast, toastMsgConfig } from "../../components/toast";
import ChangePassword from "./changePassword";

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      profile_data: [],
      success: false,
      open_change_pass_modal: false,
    };
  }

  componentDidMount = () => {
    this.getProfile();
  };

  message = (type, message) => {
    Toast(message, toastMsgConfig(type));
  };

  getProfile = async () => {
    const { user_id, student_auth } = this.props;
    let headers = {
      headers: {
        Authorization: student_auth,
        "Content-Type": "application/json",
      },
    };
    try {
      let res = await HELPERS.ApiCall(
        apis.PROFILE + user_id,
        {},
        "GET",
        "proctur",
        headers
      );
      if (res.status == 200) {
        this.setState({
          profile_data: res.data[0],
          loader: false,
          success: true,
        });
      } else {
        this.message(
          "e",
          "Unable to fetch profile details. Please try after some time!"
        );
        this.setState({ loader: false });
      }
    } catch (error) {
      if (error.response && error.response.status == 403) {
        this.props.dispatch({ type: "LOGOUT" });
      } else {
        this.message("e", ErrorMessageHandling(error));
      }
      this.setState({ loader: false });
    }
  };

  render() {
    const { loader, profile_data, success, open_change_pass_modal } =
      this.state;
    return (
      <>
        <ToastMessage />
        <ChangePassword
          show={open_change_pass_modal}
          handleClose={() => {
            this.setState({ open_change_pass_modal: false });
          }}
        />

        {!loader && success && (
          <div className="profile">
            <div className="profileHeader">
              <div className="profileImg">
                <i className="fa fa-user-circle-o"></i>
              </div>

              <div className="details">
                <h5>{profile_data.name}</h5>
                {profile_data.email && (
                  <h5>
                    <i className="fa fa-envelope-o"></i>&nbsp; &nbsp;{" "}
                    {profile_data.email}
                  </h5>
                )}
                {profile_data.mobile_no && (
                  <h5>
                    <i className="fa fa-mobile"></i>&nbsp; &nbsp; &nbsp; &nbsp;
                    {profile_data.mobile_no}
                  </h5>
                )}
                <button
                  onClick={() =>
                    this.setState({ open_change_pass_modal: true })
                  }
                >
                  Change Password
                </button>
              </div>
            </div>

            {profile_data.subjectList && profile_data.subjectList !== "" && (
              <div className="courseDetails">
                <h3>
                  <i className="fa fa-book"></i>&nbsp;&nbsp; Course Applied
                </h3>
                <ul>
                  {profile_data.subjectList.map((item, i) => (
                    <li key={i}>{item.course_name}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        )}

        {loader && (
          <div className="content">
            <div className="dashboardLoader"></div>
          </div>
        )}
        {!loader && !success && (
          <Error onReload={this.getProfile} message={""} />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return { ...state.auth };
};
export default connect(mapStateToProps)(withRouter(Profile));
