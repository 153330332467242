
import React, { Component } from 'react'
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import HELPERS, { days } from '../../utils/helper';

class HomePage extends Component {


    componentDidMount = () => {
    }


    render() {
        let dateobj = new Date();
        let date = dateobj.getUTCDate();
        let year = dateobj.getUTCFullYear();
        let day = days[dateobj.getDay()];
        var hrs = dateobj.getHours();
        var greet;
        if (hrs < 12)
            greet = 'Good Morning';
        else if (hrs >= 12 && hrs <= 17)
            greet = 'Good Afternoon';
        else if (hrs >= 17 && hrs <= 24)
            greet = 'Good Evening';
        const { user_info } = this.props;
        return (
            <>

                <div className="bg">
                    <div className="w90">
                        <div className="left">
                            <div className="date">
                                <p>{new Date().toLocaleTimeString([], { timeStyle: 'short' })}</p>
                                <div>
                                    <span>{HELPERS.getCurrentMonthName()} {date}, {year} </span>
                                    <span className="weekday">{day}</span>
                                </div>
                            </div>

                            <div className="name" >
                                <h1>{greet}, {user_info.name}!</h1>
                                <h3>Welcome back!</h3>
                            </div>
                            <div className="line" ></div>

                            <div className="qoute">
                                <q>Either you run the day, or the day runs you.</q>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}

const mapStateToProps = state => { return { ...state.auth }; };
export default connect(mapStateToProps)(withRouter(HomePage));;