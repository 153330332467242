export const messages = {
  fail_request:
    "Currently we are unable to proceed your request. Please try after sometime.",
  payment_not_allowed: "You are not allowed to do payment from application.",
  cart_contain_multiple_country_products:
    "It looks like you have different types of currency products in your cart. Kindly remove them. You can purchase only one type of currency product at a one time.",
  no_offers:
    "There are no eligible offers available for the selected products.",
  coupon_invalid: "Coupon code invalid!",
  unableToPay:
    "We are not able to process your payment at this time. Please try after some time.",
};
