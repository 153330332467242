import React, { Component } from "react";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import HELPERS from "../../utils/helper";
import apis from "../../config/apis";
import { colors } from "../../css/colors";
import CONFIG from "../../config";
import { ToastMessage, Toast, toastMsgConfig } from "../../components/toast";
import Error, { ErrorMessageHandling } from "../../components/error";

class Recordings extends Component {
  playVimeoVideo = (item) => {
    document.getElementById(
      "iframeDisplay"
    ).innerHTML = `<iframe src="https://player.vimeo.com/video/${item.vimeo_video_id}" width="320" height="240" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>            `;
  };

  render() {
    const { show, data } = this.props;
    return (
      <>
        <div className={show ? "alert display-block" : "alert display-none"}>
          <section
            className="recording"
            style={{ maxHeight: window.innerHeight - 200 }}
          >
            <button
              onClick={() => {
                this.playVimeoVideo();
              }}
            >
              Play
            </button>
            <div id="iframeDisplay"></div>
          </section>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return { ...state.auth };
};
export default connect(mapStateToProps)(withRouter(Recordings));
