import React, { useState, useEffect, Component } from "react";
import HELPERS from "../../utils/helper";
import apis from "../../config/apis";
import CONFIG from "../../config";
import {
  ToastMessage,
  Toast,
  toastMsgConfig,
  toastTypes,
} from "../../components/toast";
import ErrorPage, { ErrorMessageHandling } from "../../components/error";
import { connect } from "react-redux";

class ProductDesc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      success: false,
      desc: [],
    };
  }
  componentDidMount = () => {
    this.getProdDesc();
  };

  message = (type, message) => {
    Toast(message, toastMsgConfig(type));
  };

  getProdDesc = async () => {
    const { product, institute_id } = this.props;
    let headers = { headers: { "x-prod-inst-id": institute_id } };
    try {
      let data = await HELPERS.ApiCall(
        apis.PRODUCT_DESC + product.id,
        {},
        "GET",
        "product",
        headers
      );
      if (data.status == 200 && data.data.status_code == 200) {
        this.setState({ loader: false, desc: data.data.result, success: true });
      } else {
        this.setState({ loader: false, success: false });
        this.message("e", data.data.message);
      }
    } catch (error) {
      if (error.response && error.response.status == 403) {
        this.props.dispatch({ type: "LOGOUT" });
      } else if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        Array.isArray(error.response.data.error) &&
        error.response.data.error.length > 0
      ) {
        this.message("e", error.response.data.error[0].error_message);
      } else {
        this.message("e", ErrorMessageHandling(error));
      }
      this.setState({ loader: false, success: false });
    }
  };

  render() {
    const { product, show } = this.props;
    const showHideClassName = show
      ? "alert display-block"
      : "alert display-none";
    const { loader, desc, success } = this.state;
    return (
      <>
        <ToastMessage />
        {loader && <div className="dashboardLoader"></div>}
        <div className={showHideClassName}>
          <section
            className="prodDesc"
            style={{ maxHeight: window.innerHeight - 100 }}
          >
            <div className="prodDescHeading">
              <h5>{product.title}</h5>
              <i onClick={this.props.handleClose} className="fa fa-close"></i>
            </div>

            <div
              className="productDesc"
              style={{ margin: "10px 0px", padding: 0, width: "100%" }}
            >
              <div
                className="productDescLeft"
                style={{ margin: 0, justifyContent: "flex-start" }}
              >
                <img
                  src={product.photo_url}
                  style={{ maxWidth: "100%", height: "auto", maxHeight: 200 }}
                  alt="Product thumbnail"
                />
                <div className="descProductDates">
                  <div style={{ marginTop: 10 }}>
                    <h6 style={{ color: "gray" }}>Purchased Date</h6>
                    <h5>
                      {new Date(product.enrolled_date).toLocaleDateString()}
                    </h5>
                  </div>
                  <div style={{ marginTop: 10 }}>
                    <h6 style={{ color: "gray" }}>Expired Date</h6>
                    <h5>{new Date(product.expire_on).toLocaleDateString()}</h5>
                  </div>
                </div>
              </div>

              <div className="productDescRight">
                <div className="productDetail">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <h1>About</h1>
                  </div>
                  <p
                    className="productDetailsList"
                    dangerouslySetInnerHTML={{ __html: product.about }}
                  ></p>
                </div>

                <div className="productDetail">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <h1>Product Details</h1>
                  </div>
                  <ul style={{ paddingLeft: 40 }}>
                    {desc.map((item, i) => (
                      <li style={{ paddingTop: 10 }}>
                        <h5>{item.item_type}</h5>
                        <p
                          dangerouslySetInnerHTML={{ __html: item.description }}
                        ></p>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return { ...state.auth };
};

export default connect(mapStateToProps)(ProductDesc);
