import React from "react";
import { Provider } from "react-redux";
import Routes from "./routes";
import { store, persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";

// Either as a Class
export default class FooClass extends React.Component {
  componentDidMount = () => {
    // console.log = () => { }
  };
  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Routes />
        </PersistGate>
      </Provider>
    );
  }
}
