import React, { Component } from 'react'
import { Redirect, Route, withRouter, BrowserRouter as Router, Switch, Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import MyOrders from '../myOrders';
import apis from '../../config/apis';
import HELPERS from '../../utils/helper';
import { ToastMessage, Toast, toastMsgConfig } from '../../components/toast';
import { HeaderStrip } from './headerStrip';
import Profile from '../profile';
import LiveClass from '../liveClass';
import { dashboard } from '../../assets';
import OfflineExams from '../offlineExams';
import OnlineExams from '../onlineExams';
import StudyMaterial from '../studyMaterial';
import OnlineAssignment from '../onlineAssignment';
import Timetable from '../timeTable';
import Documents from '../documents';
import HomePage from './homePage';

export const routes = [
  {
    path: "/dashboard",
    label: 'Home',
    exact: true,
    main: () => <><HomePage /></>,
    img: dashboard.home
  }, {
    path: "/dashboard/my-orders",
    label: 'My Orders',
    exact: true,
    main: () => <><MyOrders /></>,
    img: dashboard.myOrder
  }, {
    path: "/dashboard/profile",
    label: 'My Profile',
    main: () => <><Profile /></>,
    img: dashboard.user
  },
  // {
  //   path: "/dashboard/online-exam",
  //   label: 'Online Exams',
  //   main: () => <><OnlineExams /></>,
  //   img: dashboard.onlineExam
  // }, {
  //   path: "/dashboard/study",
  //   label: 'Study Material',
  //   main: () => <><StudyMaterial /></>,
  //   img: dashboard.study
  // }, {
  //   path: "/dashboard/live-class",
  //   label: 'Online Classes',
  //   main: () => <><LiveClass /></>,
  //   img: dashboard.live
  // }, {
  //   path: "/dashboard/online-assignment",
  //   label: 'Online Assignments',
  //   main: () => <><OnlineAssignment/></>,
  //   img: dashboard.study
  // }, {
  //   path: "/dashboard/time-table",
  //   label: 'Time Table',
  //   main: () => <><Timetable/></>,
  //   img: dashboard.timetable
  // }, {
  //   path: "/dashboard/offline-exams",
  //   label: 'Offline Exams',
  //   main: () => <><OfflineExams /></>,
  //   img: dashboard.offlineExam
  // }, {
  //   path: "/dashboard/documents",
  //   label: 'Documents',
  //   main: () => <><Documents/></>,
  //   img: dashboard.documents
  // },

];


class Dashboard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      user_online: true
    };
  }

  componentDidMount() {
    this.setDetails(this.props.data)
    this.handleConnectionChange();
    window.addEventListener('online', this.handleConnectionChange);
    window.addEventListener('offline', this.handleConnectionChange);
  }

  componentWillUnmount() {
    window.removeEventListener('online', this.handleConnectionChange);
    window.removeEventListener('offline', this.handleConnectionChange);
  }

  handleConnectionChange = () => {
    this.setState({ user_online: navigator.onLine })
  }

  message = (type, message) => {
    Toast(message, toastMsgConfig(type));

  }

  onLogout = async () => {
    const { device_id, student_auth } = this.props;
    let did = device_id, st = student_auth;
    this.props.dispatch({ type: 'LOGOUT', message: '' })

    let headers = {
      headers: {
        "Authorization": st,
        "Content-Type": "application/json"
      }
    };
    try {
      let res = await HELPERS.ApiCall(apis.LOGOUT + did, {}, "GET", "proctur", headers);
    } catch (error) { }

  }

  setDetails = (data) => {
    const favicon = document.getElementById("favicon");
    if (this.props.website_enable) {
      favicon.href = data.basic_details.favicon_url;
      if (data.theme_details.text_heading_color && data.theme_details.text_heading_color !== '') {
        document.documentElement.style.setProperty("--theme-color", data.theme_details.text_heading_color);
      }
      if (data.theme_details.text_content_color && data.theme_details.text_content_color !== '') {
        document.documentElement.style.setProperty("--text-color", data.theme_details.text_content_color);
      }
    } else if (this.props.site_data && this.props.site_data.fav_icon_url) {
      favicon.href = this.props.site_data.fav_icon_url;
    }
  }


  render() {
    const { user_info } = this.props;
    const { user_online } = this.state;
    return (
      <Route
        render={({ location }) => (this.props.user_id === 0) ? <Redirect to={{ pathname: "/login", state: { from_cart: false } }} /> :
          <>
            <ToastMessage />
            {user_online &&
              <Router>
                {routes.map((route, i) => (
                  <Route
                    key={i}
                    path={route.path}
                    exact={route.exact}
                    children={<HeaderStrip
                      institute_name={user_info.institute_name}
                      navigate={this.props.history}
                      user_info={user_info}
                      onLogout={this.onLogout}
                    />}
                  />
                ))}
                <div className="page-wrapper" >

                  <nav id="sidebar" className="sidebar-wrapper" >
                    <div className="sidebar-content" >

                      <ul className="icons">
                        {routes.map((item, i) => {
                          // if (i > 1) {
                          return (
                            <Link key={i} to={item.path}>
                              <li>
                                <img src={item.img} />
                                <p>{item.label}</p>
                              </li>
                            </Link>
                          )
                          // } else {
                          //   return null;
                          // }
                        })}
                      </ul>

                      <p class="power">Powered By <br /> <span class="proctur40">Proctur 4.0</span></p>


                    </div>
                  </nav>

                  <main className="page-content">
                    <div className="w100 border">
                      <Switch>
                        {routes.map((route, i) => (
                          <Route
                            key={i}
                            path={route.path}
                            exact={route.exact}
                            children={<route.main />}
                          />
                        ))}
                      </Switch>
                    </div>
                  </main>

                </div>



              </Router>
            }

            {!user_online &&
              <div className="noNetwork bgimg2" style={{ height: window.innerHeight }} >
                <h3>You're offline right now. Check your connection.</h3>
              </div>
            }

          </>
        }
      />
    )
  }
}

const mapStateToProps = state => { return { ...state.auth, ...state.home }; };
export default connect(mapStateToProps)(withRouter(Dashboard));;