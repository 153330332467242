const initialState = {
  user_id: 0,
  usertoken: "",
  user_info: {},
  user_status: "new", // new, loggedin, notverified
  otpsent: false,
  student_auth: "",
  device_id: null,
  access_token: {},
  profile_info: [],
  institute_id: null,
  institute_name: "",
  website_enable: true,
  country_details: {},
  registration_institute: {},
  enable_online_payment_feature: true,
  show_web_access_pop_up: false,
  web_access_msg: [],
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case "LOGIN": {
      return {
        ...state,
        user_id: action.user_id,
        user_info: action.user_info,
        user_status: "loggedin",
        student_auth: action.student_auth,
        country_details: action.country_details,
        enable_online_payment_feature:
          action.enable_online_payment_feature == 1 ? true : false,
      };
    }
    case "LOGOUT": {
      let show_web_access_pop_up = action.message.includes(": app_link :")
        ? true
        : false;
      let newState = {
        ...state,
        user_id: 0,
        user_info: {},
        user_status: "new",
        otpsent: false,
        student_auth: "",
        show_web_access_pop_up,
        web_access_msg: action.message.split(": app_link :"),
        access_token: show_web_access_pop_up
          ? { ...state.access_token, web_access: false }
          : { ...state.access_token },
      };
      return { ...newState };
    }
    case "LOGOUT1": {
      console.warn("logout function called");
      return {
        user_id: 0,
        usertoken: "",
        user_info: {},
        user_status: "new", // new, loggedin, notverified
        otpsent: false,
        student_auth: "",
        device_id: null,
        access_token: {},
        profile_info: [],
        institute_id: null,
        institute_name: "",
        website_enable: true,
        country_details: {},
        registration_institute: {},
        enable_online_payment_feature: true,
        show_web_access_pop_up: false,
        web_access_msg: [],
      };
    }
    case "SET_DEVICE_ID": {
      return { ...state, device_id: action.device_id };
    }
    case "SET_PROFILE": {
      return { ...state, profile_info: action.profile_info };
    }
    case "SET_ACCESS_TOKEN": {
      return { ...state, access_token: action.access_token };
    }
    case "SET_INSTITUTE_ID": {
      return { ...state, institute_id: action.institute_id };
    }
    case "SET_INSTITUTE_NAME": {
      return {
        ...state,
        institute_name: action.institute_name,
        website_enable: action.website_enable,
      };
    }
    case "UPDATE_REGISTRATION_INSTITUTE": {
      return { ...state, registration_institute: action.data };
    }
    case "UPDATE_WEB_ACCESS_POP_UP": {
      return { ...state, show_web_access_pop_up: false, web_access_msg: [] };
    }
    default: {
      return state;
    }
  }
}

export default reducer;
