import React, { Component } from 'react'
import Header from './header';
import Footer from './footer';
import { withRouter } from 'react-router-dom';
import HELPERS from '../utils/helper';
import apis from '../config/apis';
import CONFIG from '../config';
import { ToastMessage, Toast, toastMsgConfig } from './toast';
import { ErrorMessageHandling } from './error';
import { connect } from 'react-redux';
import Playstore from '../assets/playstore.png';
import Appstore from '../assets/appstore.png'
class ThankYou extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            success: false,
            data: ''
        };
    }

    componentDidMount = () => {
        window.scrollTo(0, 0);
        if (this.props.website_enable) {
            this.getPageData('/thank-you');
        } else {
            this.setState({ loader: false })
        }
        this.props.dispatch({
            type: 'EMPTY_CART'
        })
    }

    message = (type, message) => {
        Toast(message, toastMsgConfig(type));
    }

    getPageData = async (slug) => {
        try {
            let res = await HELPERS.ApiCall(apis.GET_CLIENT_PAGE_DATA + this.props.institute_id + `?slug=${slug}`, {}, "GET", "proctur", {});
            if (res.status == 200 && res.data.status_code == 200) {
                this.setState({ loader: false, success: true, data: res.data.result })
            } else {
                this.message('e', res.data.message);
                this.setState({ loader: false, success: false })
            }
        } catch (error) {
            if (error.response && error.response.status == 403) {
                this.props.dispatch({ type: 'LOGOUT', message: error.response.data && error.response.data.message ? error.response.data.message : '' });
            } else {
                this.message('e', ErrorMessageHandling(error));
                this.setState({ loader: false, success: false })
            }
        }
    }

    render() {
        const { location, website_enable } = this.props;
        const { loader, success, data } = this.state;
        return (
            <>
                {loader &&
                    <div className="loading"></div>
                }
                <ToastMessage />
                <Header />
                <div className="thankYou">
                    <div className="thankYou-tick" >
                        <i className="fa fa-check-circle" ></i>
                    </div>
                    <h2>Thank You!</h2>
                    <h3>Payment done successfully.</h3>
                    <h3 style={{ margin: '4px 0px' }}>Purchased Product will get assigned to you within 1hr .</h3>
                    <h3 style={{ margin: '4px 0px' }}>Happy Learning!</h3>

                    {!loader && success && data.page_data && data.page_data !== '' && website_enable &&
                        <div dangerouslySetInnerHTML={{ __html: data.page_data }} style={{ margin: '10px 0px' }} />
                    }

                    {!loader && success && (!data.page_data || (data.page_data && data.page_data == '')) &&
                        <p style={{ margin: '10px 0px' }}>Thank you for your payment. An automated payment receipt will be sent to your registered email.</p>
                    }

                    {!loader && !success &&
                        <p style={{ margin: '10px 0px' }}>Thank you for your payment. An automated payment receipt will be sent to your registered email.</p>
                    }
                    {website_enable && this.props.data.social_details && ((this.props.data.social_details.android_app_url && this.props.data.social_details.android_app_url !== '') || (this.props.data.social_details.ios_app_url && this.props.data.social_details.ios_app_url !== '')) &&
                        <div style={{ padding: '20px 0px' }} >
                            <p>You can download the application using following link to view other details.</p>
                            <div className='appLinks'  >

                                {this.props.data.social_details.android_app_url && this.props.data.social_details.android_app_url !== '' &&
                                    <a href={this.props.data.social_details.android_app_url} target="_blank" className="app" style={{ marginLeft: 20 }} ><img src={Playstore} /></a>
                                }
                                {this.props.data.social_details.ios_app_url && this.props.data.social_details.ios_app_url !== '' &&
                                    <a href={this.props.data.social_details.ios_app_url} target="_blank" className="app" style={{ paddingLeft: 20 }} ><img src={Appstore} /></a>
                                }

                            </div>
                        </div>
                    }
                    <button onClick={() => { this.props.history.push("/product") }}  ><i className='fa fa-shopping-cart' ></i> Buy another product</button>

                </div>
                <Footer />
            </>
        )
    }
}

const mapStateToProps = state => { return { ...state.home, ...state.auth }; };
export default connect(mapStateToProps)(withRouter(ThankYou));
