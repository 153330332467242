import React, { Component } from 'react'
import FAQsItem from '../home/faqsItem';
import Header from '../../components/header';
import Footer from '../../components/footer';
import HELPERS from '../../utils/helper';
import apis from '../../config/apis';
import CONFIG from '../../config';
import { ToastMessage, Toast, toastMsgConfig, toastTypes } from '../../components/toast';
import ErrorPage, { ErrorMessageHandling } from '../../components/error';
import { connect } from 'react-redux';
import { faqs_api } from '../home/data';

class FAQs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      faqs: [],
      loader: true,
      success: false
    };
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
    this.getFaqs()
  }

  message = (type, message) => {
    Toast(message, toastMsgConfig(type));
  }

  getFaqs = async () => {
    try {
      let res = await HELPERS.ApiCall(apis.FAQS + this.props.institute_id + "?categoryWise=true", {}, "GET", "proctur", {});
      if (res.status == 200 && res.data.status_code == 200) {
        this.setState({
          loader: false,
          faqs: res.data.result,
          success: true
        })
      } else {
        this.setState({ loader: false, success: false });
        this.message('e', res.data.message);
      }
    } catch (error) {
      if (error.response && error.response.status == 403) {
        this.props.dispatch({ type: 'LOGOUT', message: error.response.data && error.response.data.message ? error.response.data.message : '' });
      } else if (error && error.response && error.response.message) {
        this.message('e', error.response.message)
      } else {
        this.message('e', ErrorMessageHandling(error));
      }
      this.setState({ loader: false, success: false })
    }
  }

  render() {
    const { loader, faqs, success } = this.state;
    let data = Object.keys(faqs).length > 0 ? faqs : faqs_api.result;
    return (
      <>
        <ToastMessage />
        <Header />
        {!loader && success &&
          < div className="content bgcolor2" style={{ paddingTop: 70 }}>
            <div className="mainContent" style={{ width: '80%' }} >
              <h2>FAQs</h2>
              <div style={{ border: '1px solid #f1f1f1' }} >
                {Object.keys(data).map((item, i) => (
                  <div key={i} className="faqCategoryItem" >
                    <h3>{item}</h3>
                    <div style={{ width: '90%', alignSelf: 'center' }} >
                      <FAQsItem faqs={Object.values(data[item])} />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        }
        {
          loader &&
          <div className="content">
            <div className="loading"></div>
          </div>
        }

        {/* {
          !loader && success && Object.keys(faqs).length == 0 &&
          <div className="emptyCart" style={{ height: window.innerHeight - 200 }} >
            <i className="fa fa-dropbox" style={{ marginBottom: 30, fontSize: 100 }} ></i>
            <h4>FAQs are not added by institute!</h4>
          </div>
        } */}

        {
          !loader && !success &&
          <ErrorPage
            onReload={() => {
              this.setState({ loader: true })
              this.getFaqs();
            }}
            message={""}
          />
        }
        <Footer />
      </>
    )
  }
}

const mapStateToProps = state => { return { ...state.auth }; };

export default connect(mapStateToProps)(FAQs);