import React, { Component } from 'react'
import { imageURL } from '../../utils/constants';

export default class CourseItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    render() {
        const { item, index } = this.props;
        let str = Math.random().toString(36).slice(2);
        return (
            <>
                <div className="courseDiv">

                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', width: '100%' }} >
                        <img
                            src={item.image && item.image !== '' ? item.image : (item.thumbnail_url && item.thumbnail_url !== '') ? (item.thumbnail_url + "?" + str) : imageURL}
                            style={{ height: 180, width: '100%', marginBottom: 6 }}
                            alt="Course thumbnail"
                        />
                        <h4>{item.course_type ? item.course_type : item.name}</h4>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', height: 190 }} >
                        <p>{item.short_description}</p>
                        <button onClick={() => { this.props.showLongDesc(); }} >View Details</button>
                    </div>

                </div>
            </>
        )
    }
}

