import React, { Component } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { item_list_responsive } from "../../utils/constants";
import Avatar from "./../../assets/avatar3.jpg";

export default class Testimonials extends Component {
  renderRatings = (val) => {
    let item = [];
    let add_half_star = false;
    if (val % 1 !== 0) {
      add_half_star = true;
      val = val - 0.5;
    }
    for (let i = 0; i < val; i++) {
      item.push(<i key={i} className="fa fa-star"></i>);
    }
    if (add_half_star) {
      item.push(<i key={item.length + 1} className="fa fa-star-half-o"></i>);
    }
    while (item.length !== 5) {
      item.push(<i key={item.length + 1} className="fa fa-star-o"></i>);
    }
    return item;
  };

  render() {
    const { testimonials } = this.props;
    return (
      <>
        <div id="somerandomid" className="content bgcolor">
          <div className="mainContent" style={{ width: "84%" }}>
            <h2>TESTIMONIALS</h2>
            <div className="testContainer">
              <Carousel
                responsive={item_list_responsive}
                autoPlay={true}
                infinite={true}
                autoPlaySpeed={7000}
                focusOnSelect={true}
                showDots={true}
                arrows={false}
              >
                {testimonials.map((item, index) => (
                  <div key={index} className="testDiv">
                    <img
                      className="testImg"
                      src={item.image_url ? item.image_url : Avatar}
                    />
                    <div className="testContent">
                      <p className="rating">
                        {this.renderRatings(item.rating)}
                      </p>
                      <h4>{item.name}</h4>
                      <p>{item.content}</p>
                    </div>
                  </div>
                ))}
              </Carousel>
            </div>
          </div>
        </div>
      </>
    );
  }
}
