import React, { Component } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { Link, withRouter } from "react-router-dom";
import BackgroundImage from "../../assets/7155.png";
import {
  ToastMessage,
  Toast,
  toastMsgConfig,
  toastTypes,
} from "../../components/toast";
import { messages } from "./constant";
import HELPERS from "../../utils/helper";
import apis from "../../config/apis";
import Alert from "../../components/alert";
import { connect } from "react-redux";
import Forgot from "./forgot";
import MultiInstitute from "./multiInstitute";
import { ErrorMessageHandling } from "../../components/error";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      success: false,
      mobile_number: "",
      password: "",
      show_alert: false,
      heading: "",
      content: "",
      isChecked: false,
      show_forgot_password: false,
      disabled_button: false,
      show_user_role_list: false,
      user_role_list: [],
      link: "",
    };
  }
  handleClose = () => {};
  handleOk = () => {};

  componentDidMount = () => {
    window.scrollTo(0, 0);
    this.getWebAccessToken();
  };

  getWebAccessToken = async () => {
    let res = await HELPERS.ApiCall(
      apis.WEB_ACCESS_TOKEN + this.props.institute_id,
      {},
      "GET",
      "proctur",
      {}
    );
    if (res.status == 202 && res.data.statusCode == 200) {
      this.props.dispatch({
        type: "SET_ACCESS_TOKEN",
        access_token: res.data.result,
      });
      this.props.dispatch({
        type: "UPDATE_REGISTRATION_INSTITUTE",
        data: res.data.result,
      });
    }
  };

  message = (type, message) => {
    Toast(message, toastMsgConfig(type));
  };

  isNumber = (num) => {
    return /^\d+$/.test(num);
  };

  validate = () => {
    this.setState({ disabled_button: true });
    setTimeout(() => {
      this.setState({ disabled_button: false });
    }, 2000);
    const { mobile_number, password } = this.state;
    if (!mobile_number || (mobile_number && mobile_number.length < 0)) {
      this.message("e", "Please enter valid Email or Phone number for login.");
    } else if (
      this.isNumber(mobile_number) &&
      !HELPERS.validateMobileNumber(mobile_number)
    ) {
      this.message("e", messages.invalid_number);
    } else if (
      !this.isNumber(mobile_number) &&
      !HELPERS.validateEmail(mobile_number)
    ) {
      this.message("e", messages.invalid_email);
    } else if (!password || (password && password.length < 0)) {
      this.message("e", messages.invalid_pass);
    } else if (password && password.length < 4) {
      this.message("e", "Password length should be atleast 4 digits long.");
    } else {
      this.login(false, this.props.device_id, -1, this.props.institute_id, -1);
    }
  };

  login = async (renew, device_id, user_id, institute_id, user_type) => {
    // this.setState({ loader: true });
    let payload = {
      device_id: device_id ? device_id : null,
      institute_id: institute_id,
      source: "WEB",
      logout_from_all_devices: renew,
      password: this.state.password,
      user_id: user_id,
      user_name: this.state.mobile_number,
      user_type: user_type,
    };
    let student_auth;
    try {
      let res = await HELPERS.ApiCall(
        apis.LOGIN,
        payload,
        "POST",
        "proctur",
        {}
      );
      // this.setState({ loader: false });
      if (
        res.status == 201 &&
        res.data.statusCode == 200 &&
        Array.isArray(res.data.result)
      ) {
        //multi user case
        let arr = res.data.result.filter(
          (el) => el.user_type == 1 || el.user_type == 99
        ); // remove parent data
        if (arr.length > 1) {
          await this.setState({
            show_user_role_list: true,
            user_role_list: arr,
          });
        } else if (arr.length == 1) {
          this.login(
            false,
            this.props.device_id,
            arr[0].user_id,
            arr[0].institute_id,
            arr[0].user_type
          );
        } else {
          this.message("e", messages.login_not_allowed);
        }
      } else if (
        res.status == 201 &&
        res.data.statusCode == 200 &&
        !Array.isArray(res.data.result) &&
        res.data.result.login_option == 10
      ) {
        //login option 10 sso case
        if (res.data.result.device_id) {
          this.props.dispatch({
            type: "SET_DEVICE_ID",
            device_id: res.data.result.device_id,
          });
        }
        this.renewLoginSession(
          res.data.result.device_id,
          res.data.result.user_id
        );
      } else if (
        res.status == 201 &&
        res.data.statusCode == 200 &&
        !Array.isArray(res.data.result) &&
        res.data.result.login_option == 11
      ) {
        //login option 11 sso case and single device login case
        if (res.data.result.device_id) {
          this.props.dispatch({
            type: "SET_DEVICE_ID",
            device_id: res.data.result.device_id,
          });
        }
        this.cancelLogin();
      } else if (
        res.status == 201 &&
        res.data.statusCode == 200 &&
        !Array.isArray(res.data.result) &&
        res.data.result.login_option == 13
      ) {
        //login option 13 block case
        if (res.data.result.device_id) {
          this.props.dispatch({
            type: "SET_DEVICE_ID",
            device_id: res.data.result.device_id,
          });
        }
        alert(
          "Your account has been blocked. Please contact your institute/school!"
        );
      } else if (
        res.data.result.user_type == 1 ||
        res.data.result.user_type == 99
      ) {
        let loginRes = { ...res.data.result, ...res.data.result.inst_plan_det };
        //login case
        if (res.data.result.device_id) {
          this.props.dispatch({
            type: "SET_DEVICE_ID",
            device_id: res.data.result.device_id,
          });
        }
        if (res.data.result.enable_single_device_login) {
          student_auth = btoa(
            res.data.result.user_id +
              "|" +
              res.data.result.user_type +
              ":" +
              res.data.result.password +
              ":" +
              res.data.result.institute_id +
              ":" +
              res.data.result.device_id +
              ":" +
              "WEB"
          );
        } else {
          student_auth = btoa(
            res.data.result.user_id +
              "|" +
              res.data.result.user_type +
              ":" +
              res.data.result.password +
              ":" +
              res.data.result.institute_id
          );
        }
        let country_details = {
          country_id: loginRes.country_id,
          country_calling_code: loginRes.country_calling_code,
          currency_code: loginRes.currency_code,
        };
        this.props.dispatch({
          type: "LOGIN",
          user_id: loginRes.user_id,
          user_info: loginRes,
          student_auth: student_auth,
          country_details: country_details,
          enable_online_payment_feature: loginRes.enable_online_payment_feature,
        });
        if (
          this.props.location &&
          this.props.location.state &&
          this.props.location.state.from_cart &&
          this.props.cart.length > 0
        ) {
          this.props.history.push("/cart");
        } else {
          this.props.history.push("/");
        }
      } else {
        this.message("e", messages.login_not_allowed);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.status == 403 &&
        error.response.data &&
        error.response.data.message &&
        error.response.data.message &&
        error.response.data.message.includes(": app_link :")
      ) {
        let web_access_msg = error.response.data.message.split(": app_link :");
        this.handleClose = () => {
          this.setState({ show_alert: false });
        };
        this.handleOk = () => {
          this.setState({ show_alert: false });
        };
        await this.setState({
          show_alert: true,
          heading: "",
          content: web_access_msg[0],
          link: web_access_msg[1],
        });
      } else {
        this.message("e", ErrorMessageHandling(error));
      }
      this.setState({ loader: false });
    }
  };

  renewLoginSession = async (device_id, user_id) => {
    this.handleClose = () => {
      this.setState({ show_alert: false });
    };
    this.handleOk = () => {
      this.login(true, device_id, user_id, 0, -1);
      this.setState({ show_alert: false });
    };
    await this.setState({
      show_alert: true,
      heading: "Renew Session",
      content: messages.renew_session,
    });
  };

  cancelLogin = async () => {
    this.handleClose = () => {
      this.setState({ show_alert: false });
    };
    this.handleOk = () => {
      this.setState({ show_alert: false });
    };
    await this.setState({
      show_alert: true,
      heading: "Unable to login!",
      content: messages.not_registered_device,
    });
  };

  render() {
    const {
      mobile_number,
      password,
      show_alert,
      heading,
      content,
      show_user_role_list,
      user_role_list,
      loader,
      show_forgot_password,
      disabled_button,
      link,
    } = this.state;
    const { access_token } = this.props;
    return (
      <>
        {loader && <div className="loading"></div>}
        <ToastMessage />
        <Header />
        <div className="content bgimg2" style={{ paddingTop: 70 }}>
          <div
            className="mainContent"
            style={{ backgroundImage: `url${BackgroundImage}` }}
          >
            <div className="block">
              <h4>Student Login</h4>

              <label className="inputLabel">
                Email or Phone:
                <input
                  className="loginInput"
                  value={mobile_number}
                  placeholder="Enter Email or Phone"
                  onChange={(e) => {
                    // const re = /^[0-9\b]+$/;
                    // if (e.target.value === '' || re.test(e.target.value)) {
                    this.setState({ mobile_number: e.target.value });
                    // }
                  }}
                />
              </label>

              <label className="inputLabel">
                Password:
                <input
                  className="loginInput"
                  onChange={(e) => {
                    this.setState({ password: e.target.value });
                  }}
                  value={password}
                  required={true}
                  type="password"
                  name="password"
                  placeholder="Enter Password"
                  minLength={4}
                />
              </label>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <a
                  className="forgot"
                  onClick={() => {
                    this.setState({ show_forgot_password: true });
                  }}
                >
                  Forgot Password ?
                </a>
              </div>

              <button
                disabled={disabled_button}
                onClick={this.validate}
                style={{ opacity: disabled_button ? 0.5 : 1 }}
              >
                LOG IN
              </button>
            </div>
            {this.props.site_data && !this.props.site_data.school_model && (
              <h5 className="link">
                Don't have an account ?{" "}
                {access_token.web_access ? (
                  <Link
                    to={{
                      pathname: "/register",
                      state: {
                        from_cart:
                          this.props.location &&
                          this.props.location.state &&
                          this.props.location.state.from_cart
                            ? true
                            : false,
                      },
                    }}
                  >
                    {" "}
                    Register here
                  </Link>
                ) : (
                  <Link
                    onClick={() => {
                      this.handleClose = () => {
                        this.setState({ show_alert: false });
                      };
                      this.handleOk = () => {
                        this.setState({ show_alert: false });
                      };
                      this.setState({
                        show_alert: true,
                        heading: "",
                        content:
                          "You are not authorised to register yourself. Kindly download this link to register yourself.",
                        link: this.props.data.social_details.android_app_url
                          ? this.props.data.social_details.android_app_url
                          : "https://play.google.com/store/apps/details?id=com.proctur.procturlearnstudent",
                      });
                    }}
                  >
                    Register here
                  </Link>
                )}
              </h5>
            )}
          </div>
        </div>

        <Forgot
          show={show_forgot_password}
          handleClose={() => this.setState({ show_forgot_password: false })}
        />

        {show_user_role_list && (
          <MultiInstitute
            show={show_user_role_list}
            user_role_list={user_role_list}
            handleClose={() => {
              this.setState({ show_user_role_list: false });
            }}
            login={(item) => {
              this.login(
                false,
                this.props.device_id,
                item.user_id,
                item.institute_id,
                item.user_type
              );
            }}
          />
        )}
        <Alert
          show={show_alert}
          heading={heading}
          content={content}
          handleClose={this.handleClose}
          handleOk={this.handleOk}
          link={link}
          hideClose={link.length == 0 ? false : true}
        />

        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return { ...state.auth, ...state.cart, ...state.home };
};

export default connect(mapStateToProps)(withRouter(Login));
