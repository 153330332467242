import React, { Component } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import HELPERS from "../../utils/helper";
import apis from "../../config/apis";
import { withRouter } from "react-router-dom";
import { colors } from "../../css/colors";
import { connect } from "react-redux";
import OfferTag from "../../assets/discount.png";
import CONFIG from "../../config";
import {
  ToastMessage,
  Toast,
  toastMsgConfig,
  toastTypes,
} from "../../components/toast";
import ErrorPage, { ErrorMessageHandling } from "../../components/error";
import { imageURL, product_video_responsive } from "../../utils/constants";
import ReactPlayer from "react-player";
import Carousel from "react-multi-carousel";
import play from "./../../assets/play_video.png";
import pause from "./../../assets/pause_video.png";
import axios from "axios";
import Iframe from "react-iframe";
import {
  RiLoader2Line,
  RiDownload2Line,
  RiPencilLine,
  RiArrowLeftLine,
  RiCheckboxCircleLine,
  RiCloseCircleLine,
  RiAddLine,
} from "react-icons/ri";

class ProductDesc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      success: false,
      loader3: false,

      loader1: true,
      success1: false,

      desc: [],
      product: {},
      videos: [],
      currVideoIndex: 0,
      playing: false,
      show_product_image_only: true,
      send_consumption: 0,
    };
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
    this.getProdDesc();
  };

  message = (type, message) => {
    Toast(message, toastMsgConfig(type));
  };

  getYoutubeVideoId = (url) => {
    url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    return url[2] !== undefined ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0];
  };

  handlePlayYoutubeAndMp4 = (el, id) => {
    const {
      loader,
      product,
      desc,
      success,
      loader1,
      success1,
      videos,
      currVideoIndex,
      playing,
      send_consumption,
    } = this.state;
    if (el?.type == "video_url") {
      if (!send_consumption && el.url) {
        this.calculateCloudConsumption(el.url, el, id);
      } else {
        this.setState({
          show_product_image_only: false,
          currVideoIndex: id,
          playing: currVideoIndex == id ? !playing : true,
          send_consumption: send_consumption + 1,
        });
      }
    } else {
      this.setState({
        show_product_image_only: false,
        currVideoIndex: id,
        playing: currVideoIndex == id ? !playing : true,
      });
    }
  };

  calculateCloudConsumption = async (aws_video_url, el, id) => {
    // Deduct Consumption via calling API only for aws-videos and for 1st time play only
    const {
      loader,
      product,
      desc,
      success,
      loader1,
      success1,
      videos,
      currVideoIndex,
      playing,
      send_consumption,
    } = this.state;
    const { location, institute_id } = this.props;
    const initials =
      CONFIG.PRODUCT_BASE_URL +
      "prod/product/update-consumed-storage?videoUrl=";
    let url = initials + aws_video_url;
    const headers = {
      "x-proc-authorization":
        this.props.user_id == 0
          ? this.props.access_token.auth_token
          : this.props.student_auth,
      "x-prod-inst-id": institute_id,
      "x-prod-user-id": undefined,
    };
    let body = {};
    this.setState({ loader3: true });
    await axios
      .put(url, body, { headers: headers })
      .then((res) => {
        this.setState({ loader3: false });
        this.setState({
          show_product_image_only: false,
          currVideoIndex: id,
          playing: currVideoIndex == id ? !playing : true,
          send_consumption: send_consumption + 1,
        });
      })
      .catch((error) => {
        this.setState({ loader3: false });
        if (error.response && error.response.status == 403) {
          this.props.dispatch({
            type: "LOGOUT",
            message:
              error.response.data && error.response.data.message
                ? error.response.data.message
                : "",
          });
        } else if (
          error.response &&
          error.response.data &&
          error.response.data.error &&
          Array.isArray(error.response.data.error) &&
          error.response.data.error.length > 0
        ) {
          this.message("e", error.response.data.error[0].error_message);
        } else {
          this.message("e", ErrorMessageHandling(error));
        }
      });
  };

  getProdDesc = async () => {
    const { location, institute_id } = this.props;
    let headers = {
      headers: {
        "x-prod-inst-id": institute_id,
        Authorization:
          this.props.user_id == 0
            ? this.props.access_token.auth_token
            : this.props.student_auth,
      },
    };
    try {
      let data = await HELPERS.ApiCall(
        apis.PRODUCT_DESC + location.state.item.id,
        {},
        "GET",
        "product",
        headers
      );
      if (data.status == 200 && data.data.status_code == 200) {
        if (data?.data?.result?.length) {
          let temp = [];
          let el = data.data.result[0];
          if (el.youtube_url) {
            let temp_youtube_url_list = JSON.parse(el.youtube_url);
            temp_youtube_url_list.map((item, idx) => {
              temp.push({
                url: item,
                type: "youtube_url",
                thumbnail:
                  "https://img.youtube.com/vi/" +
                  this.getYoutubeVideoId(item) +
                  "/0.jpg",
              });
            });
          }

          if (el.video_url) {
            let type;
            let extension = el.video_url.split(".").length
              ? el.video_url
                  .split(".")
                  [el.video_url.split(".").length - 1].split(" ")
                  .join("")
              : "";
            extension = extension.length <= 10 ? extension : "";
            if (
              typeof (
                el.video_url == "string" &&
                el.video_url.length &&
                el.video_url.length > 4
              )
            ) {
              extension == "mp4"
                ? temp.push({
                    url: el.video_url,
                    type: "video_url",
                    format: extension,
                  })
                : temp.push({ url: el.video_url, type: "video_url" });
            } else {
              temp.push({ url: el.video_url, type: "video_url" });
            }
          }
          this.setState({ videos: temp });
        }

        if (location.state.item.from_direct_url) {
          this.getProdDetails();
        } else {
          this.setState({ loader1: false, success1: true });
        }
        this.setState({
          loader: false,
          desc: data.data.result,
          success: true,
          product: location.state.item,
        });
      } else {
        this.setState({ loader: false, success: false, loader1: false });
        this.message("e", data.data.message);
      }
    } catch (error) {
      if (error.response && error.response.status == 403) {
        this.props.dispatch({
          type: "LOGOUT",
          message:
            error.response.data && error.response.data.message
              ? error.response.data.message
              : "",
        });
      } else if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        Array.isArray(error.response.data.error) &&
        error.response.data.error.length > 0
      ) {
        this.message("e", error.response.data.error[0].error_message);
      } else {
        this.message("e", ErrorMessageHandling(error));
      }
      this.setState({ loader: false, success: false, loader1: false });
    }
  };

  getProdDetails = async () => {
    const { location } = this.props;

    let url = `${apis.PRODUCT_INFO}?proc=${location.state.item.title}%23$${location.state.item.id}%23$${location.state.ins_id}`;
    try {
      let data = await HELPERS.ApiCall(url, {}, "GET", "product", {
        Authorization:
          this.props.user_id == 0
            ? this.props.access_token.auth_token
            : this.props.student_auth,
      });
      if (data.status == 200 && data.data.status_code == 200) {
        this.setState({
          loader1: false,
          product: { ...data.data.result[0] },
          success1: true,
        });
      } else {
        this.setState({ loader1: false, success1: false });
        this.message("e", data.data.message);
      }
    } catch (error) {
      if (error.response && error.response.status == 403) {
        this.props.dispatch({
          type: "LOGOUT",
          message:
            error.response.data && error.response.data.message
              ? error.response.data.message
              : "",
        });
      } else if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        Array.isArray(error.response.data.error) &&
        error.response.data.error.length > 0
      ) {
        this.message("e", error.response.data.error[0].error_message);
      } else {
        this.message("e", ErrorMessageHandling(error));
      }
      this.setState({ loader1: false, success1: false });
    }
  };

  addToCart = (item, from_direct_url) => {
    let id = from_direct_url ? item.product_id : item.id;
    if (!this.props.cart.includes(id)) {
      this.props.dispatch({
        type: "ADD_TO_CART",
        payload: {
          id: [id, ...this.props.cart],
        },
      });
    }
  };

  removeFromCart = (item, from_direct_url) => {
    let id = from_direct_url ? item.product_id : item.id;
    this.props.dispatch({
      type: "REMOVE_FROM_CART",
      payload: {
        id: id,
      },
    });
  };

  render() {
    const {
      loader,
      product,
      desc,
      success,
      loader1,
      success1,
      videos,
      currVideoIndex,
      playing,
      send_consumption,
      loader3,
    } = this.state;
    const { cart, location } = this.props;
    let from_direct_url = location.state.item.from_direct_url;
    return (
      <>
        <ToastMessage />
        <Header />
        {!loader && success && !loader1 && success1 && !loader3 && (
          <div
            className="content bgimg2"
            style={{ paddingTop: 100, paddingBottom: 30 }}
          >
            <div className="productDesc">
              <div className="productDescLeft">
                {videos.length && this.state.show_product_image_only ? (
                  <img
                    className="product_video_player"
                    style={{
                      width: "32vw",
                      maxWidth: "550px",
                      objectFit: "contain",
                    }}
                    src={
                      product.logo_url == undefined
                        ? product.product_logo_url == undefined
                          ? imageURL
                          : product.product_logo_url
                        : product.logo_url
                    }
                  />
                ) : (
                  ""
                )}
                {videos.length ? (
                  <div>
                    {!this.state.show_product_image_only && (
                      <div>
                        {(videos[currVideoIndex]?.format &&
                          videos[currVideoIndex]?.format == "mp4") ||
                        videos[currVideoIndex].type == "youtube_url" ? (
                          <div className="product_video_player">
                            <ReactPlayer
                              className="react-player"
                              playing={playing}
                              url={videos[currVideoIndex].url}
                              width="92%"
                              height="100%"
                              controls={false}
                            />
                          </div>
                        ) : (
                          <a
                            target="_blank"
                            href={videos[currVideoIndex].url}
                            style={{ position: "relative" }}
                          >
                            <div
                              className="product_video_player"
                              style={{
                                background: "#D4D5D4",
                                opacity: 0.7,
                                borderRadius: "5px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <RiDownload2Line size={20} />
                            </div>
                          </a>
                        )}
                      </div>
                    )}
                    <div
                      style={{
                        width: "400px",
                        width: "35vw",
                        maxWidth: "550px",
                      }}
                    >
                      <Carousel
                        responsive={product_video_responsive}
                        autoPlay={false}
                        infinite={true}
                        focusOnSelect={false}
                        showDots={false}
                        arrows={true}
                      >
                        {videos.map((el, id) =>
                          (el?.format && el?.format == "mp4") ||
                          el.type == "youtube_url" ? (
                            <div
                              style={{
                                position: "relative",
                                margin: "15px 1% 15px 0",
                                height: "95px",
                                width: "95px",
                                backgroundImage:
                                  "linear-gradient(rgb(218, 223, 225), rgb(236, 240, 241))",
                                borderRadius: "7px",
                                overflow: "hidden",
                              }}
                              onClick={() => {
                                this.handlePlayYoutubeAndMp4(el, id);
                              }}
                            >
                              <img
                                style={{
                                  position: "absolute",
                                  height: "100%",
                                  width: "100%",
                                  objectFit: "fill",
                                  zIndex: 0,
                                }}
                                src={
                                  el?.thumbnail
                                    ? el.thumbnail
                                    : product.logo_url == undefined
                                    ? product.product_logo_url == undefined
                                      ? imageURL
                                      : product.product_logo_url
                                    : product.logo_url
                                }
                              />
                              <div
                                style={{
                                  position: "absolute",
                                  zIndex: 1,
                                  height: "100%",
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  border: 0,
                                }}
                              >
                                <img
                                  style={{
                                    height: "20px",
                                    width: "20px",
                                    zIndex: 1,
                                    border: 0,
                                  }}
                                  src={
                                    currVideoIndex == id
                                      ? playing
                                        ? pause
                                        : play
                                      : play
                                  }
                                />
                              </div>
                            </div>
                          ) : (
                            <a
                              target="_blank"
                              href={el.url}
                              style={{ marginRight: "10px" }}
                            >
                              <div
                                style={{
                                  position: "relative",
                                  margin: "15px 1% 15px 0",
                                  height: "95px",
                                  width: "95px",
                                  backgroundImage:
                                    "linear-gradient(rgb(218, 223, 225), rgb(236, 240, 241))",
                                  borderRadius: "7px",
                                  overflow: "hidden",
                                }}
                                onClick={() => {
                                  this.setState({
                                    show_product_image_only: false,
                                    currVideoIndex: id,
                                  });
                                }}
                              >
                                <img
                                  style={{
                                    position: "absolute",
                                    height: "100%",
                                    width: "100%",
                                    objectFit: "fill",
                                    zIndex: 0,
                                    opacity: 0.5,
                                  }}
                                  src={
                                    el?.thumbnail
                                      ? el.thumbnail
                                      : product.logo_url == undefined
                                      ? product.product_logo_url == undefined
                                        ? imageURL
                                        : product.product_logo_url
                                      : product.logo_url
                                  }
                                />
                                <div
                                  style={{
                                    position: "absolute",
                                    zIndex: 1,
                                    height: "100%",
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    border: 0,
                                  }}
                                >
                                  <RiDownload2Line />
                                </div>
                              </div>
                            </a>
                          )
                        )}
                      </Carousel>
                    </div>
                  </div>
                ) : (
                  <img
                    className="product_video_player"
                    style={{
                      width: "32vw",
                      maxWidth: "550px",
                      objectFit: "contain",
                    }}
                    src={
                      product.logo_url == undefined
                        ? product.product_logo_url == undefined
                          ? imageURL
                          : product.product_logo_url
                        : product.logo_url
                    }
                  />
                )}
              </div>

              <div className="productDescRight">
                <div className="productDescLeftDetail" style={{}}>
                  <h1>{product.title}</h1>

                  <h3 style={{ display: "flex" }}>
                    {product.is_paid == "N" ? (
                      <div>Free &nbsp;&nbsp;</div>
                    ) : (
                      (product.country_details &&
                      product.country_details.currency_code
                        ? product.country_details.currency_code == "INR"
                          ? "₹"
                          : product.country_details.currency_code
                        : "") +
                      " " +
                      (product.price == undefined
                        ? product.actual_price
                        : product.price)
                    )}
                    {product.discount_percentage > 0 && (
                      <span className="discount">
                        {" "}
                        {product.country_details &&
                        product.country_details.currency_code
                          ? product.country_details.currency_code == "INR"
                            ? "₹"
                            : product.country_details.currency_code
                          : ""}{" "}
                        {product.price_before_discount}
                      </span>
                    )}
                    {product.discount_percentage > 0 && (
                      <span
                        className="otherTag"
                        style={{
                          color: "green",
                          fontWeight: 700,
                          paddingLeft: 6,
                          paddingRight: 10,
                        }}
                      >
                        {" "}
                        {product.discount_percentage} % off on product
                      </span>
                    )}
                    <div className="otherTag">
                      &nbsp;&nbsp;&nbsp;
                      {product.offer_available && (
                        <div>
                          <img src={OfferTag} />
                          <h5 style={{ color: colors.CRUSTA }}>
                            Offers Available
                          </h5>
                        </div>
                      )}
                      {product.purchase_count_status !== 0 &&
                        (product.total_prod_purchaseed > 0 ||
                          product.total_product_sold > 0) && (
                          <h2
                            style={{
                              color: colors.PICTON_BLUE,
                              marginTop: "2px",
                            }}
                          >
                            {product.total_prod_purchaseed == undefined
                              ? product.total_product_sold
                              : product.total_prod_purchaseed}{" "}
                            Student(s) Purchased
                          </h2>
                        )}
                    </div>
                  </h3>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    {cart &&
                      cart.includes(product.id) &&
                      !product.is_enrolled && (
                        <button
                          style={{
                            marginRight: 4,
                            color: colors.CARDINAL,
                            borderColor: colors.CARDINAL,
                          }}
                          onClick={() =>
                            this.removeFromCart(product, from_direct_url)
                          }
                        >
                          Remove from Cart <i className="fa fa-remove"></i>
                        </button>
                      )}

                    {from_direct_url &&
                      cart &&
                      cart.includes(product.product_id) && (
                        <button
                          style={{
                            marginRight: 4,
                            color: colors.CARDINAL,
                            borderColor: colors.CARDINAL,
                          }}
                          onClick={() =>
                            this.removeFromCart(product, from_direct_url)
                          }
                        >
                          Remove from Cart <i className="fa fa-remove"></i>
                        </button>
                      )}

                    {(!cart || (cart && !cart.includes(product.id))) &&
                      product.remaining_products > 0 &&
                      !product.is_enrolled && (
                        <button
                          style={{ marginRight: 4 }}
                          onClick={() =>
                            this.addToCart(product, from_direct_url)
                          }
                        >
                          Add to Cart <i className="fa fa-shopping-cart"></i>
                        </button>
                      )}

                    {from_direct_url &&
                      (!cart || (cart && !cart.includes(product.product_id))) &&
                      !product.sold_out && (
                        <button
                          style={{ marginRight: 4 }}
                          onClick={() =>
                            this.addToCart(product, from_direct_url)
                          }
                        >
                          Add to Cart <i className="fa fa-shopping-cart"></i>
                        </button>
                      )}

                    {product.remaining_products > 0 && !product.is_enrolled && (
                      <button
                        style={{ marginRight: 4, marginLeft: 10 }}
                        onClick={() => {
                          this.addToCart(product, from_direct_url);
                          this.props.history.push("/cart");
                        }}
                      >
                        Buy Now
                      </button>
                    )}

                    {from_direct_url && !product.sold_out && (
                      <button
                        style={{ marginRight: 4, marginLeft: 10 }}
                        onClick={() => {
                          this.addToCart(product, from_direct_url);
                          this.props.history.push("/cart");
                        }}
                      >
                        Buy Now
                      </button>
                    )}

                    {product.remaining_products == 0 &&
                      !product.is_enrolled && (
                        <button
                          disabled={true}
                          style={{
                            marginRight: 4,
                            color: colors.CRUSTA,
                            borderColor: colors.CRUSTA,
                            fontWeight: 700,
                            borderWidth: 0,
                            paddingLeft: 0,
                          }}
                        >
                          Sold Out
                        </button>
                      )}

                    {from_direct_url && product.sold_out && (
                      <button
                        disabled={true}
                        style={{
                          marginRight: 4,
                          color: colors.CRUSTA,
                          borderColor: colors.CRUSTA,
                          fontWeight: 700,
                          borderWidth: 0,
                          paddingLeft: 0,
                        }}
                      >
                        Sold Out
                      </button>
                    )}

                    {product.is_enrolled && (
                      <button
                        disabled={true}
                        style={{
                          marginRight: 4,
                          color: colors.JADE,
                          borderColor: colors.JADE,
                          fontWeight: 700,
                          borderWidth: 0,
                          paddingLeft: 0,
                        }}
                      >
                        <i className="fa fa-check" style={{ padding: 0 }}></i>{" "}
                        Purchased
                      </button>
                    )}
                  </div>
                </div>
                <div className="productDetail">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <h1>About</h1>
                  </div>
                  <p
                    className="productDetailsList"
                    dangerouslySetInnerHTML={{ __html: product.about }}
                  ></p>
                </div>

                <div className="productDetail">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <h1>Product Details</h1>
                  </div>
                  <ul style={{ paddingLeft: 40 }}>
                    {desc.map((item, i) => (
                      <li style={{ paddingTop: 10 }}>
                        <h5>{item.item_type}</h5>
                        <p
                          dangerouslySetInnerHTML={{ __html: item.description }}
                        ></p>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )}

        {(loader || loader1 || loader3) && (
          <div className="content">
            <div className="loading"></div>
          </div>
        )}

        {!loader && !loader1 && !loader3 && (!success || !success1) && (
          <ErrorPage
            onReload={() => {
              this.setState({ loader: true, loader1: true });
              this.getProdDesc();
              this.getProdDetails();
            }}
            message={""}
          />
        )}

        <Footer />
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return { ...state.cart, ...state.auth };
};

export default connect(mapStateToProps)(withRouter(ProductDesc));
